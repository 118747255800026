<template lang="pug">
  .module__main
    .module-content__container
      .filters__container
        .handler-radio__container
          el-radio(v-model="handler" label="finex") FX
        .ps-radio__container
          el-radio(v-model="payment_system" label="card") Карты
          el-radio(v-model="payment_system" label="sbp") СБП
      .table__container
        .table-list__container
          el-table(
            v-loading="isLoading"
            :data="handlerTraders"
            style="width: 100%; font-size: 12px"
          )
            el-table-column(prop="name" label="Трейдер" width="200")
            el-table-column(prop="ad_id" label="ID объявления" width="200")
            el-table-column(label="Приоритет" width="200")
              template(slot-scope="scope")
                .priority__container
                  el-input(v-model="scope.row.priority")
                  el-button.update__btn(
                    icon="el-icon-refresh"
                    circle type="success"
                    size="mini"
                    @click="editTraderPriority(scope.row.name, scope.row.priority)"
                  )
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { EDIT_TRADER, LOAD_TRADERS } from "../../store/const/settings";

export default {
  name: 'TradersList',
  data() {
    return {
      traders: [],
      handler: 'finex',
      payment_system: 'card',
    }
  },
  async mounted() {
    await this.getTraders();
  },
  computed: {
    ...mapGetters([
      'loading',
      'error',
    ]),
    isLoading() {
      return this.loading(LOAD_TRADERS) === 'loading';
    },
    isError() {
      return this.loading(LOAD_TRADERS) === 'error';
    },
    errorMessage() {
      return this.error(LOAD_TRADERS);
    },
    handlerTraders() {
      return this.traders.filter((trader) => trader.handler === this.handler && trader.payment_system === this.payment_system);
    },
  },
  methods: {
    ...mapActions('settings', {
      loadTraders: LOAD_TRADERS,
      editTrader: EDIT_TRADER,
    }),
    async getTraders() {
      const tradersRequest = await this.loadTraders();
      if (tradersRequest.success) {
        this.traders = tradersRequest.data.sort((a, b) => a.priority - b.priority);
      }
    },
    async editTraderPriority(name, priority) {
      await this.startEditTrader(name, { priority } );
    },
    async startEditTrader(name, changes = {}) {
      const editRequest = await this.editTrader({ name, changes, handler: this.handler, payment_system: this.payment_system });
      if (editRequest.success) {
        this.$message.success('Изменение трейдера успешно');
        await this.getTraders();
      } else {
        this.$message.error(`Ошибка изменения трейдера: ${editRequest.message || 'Неизвестная ошибка'}`);
      }
    },
  }
};
</script>
<style scoped>
.filters__container {
    padding: 5px;
    background-color: white;
    margin-bottom: 5px;
}

.priority__container {
    display: flex;
}

.priority__container .update__btn {
    padding: 12px;
    margin-left: 5px;
}
</style>
