<template lang="pug">
.module__main
  .module-content__container(v-loading="isLoading")
    .stats__container
      .filters__container
        .date__container
          el-date-picker(
            v-model="date"
            type="datetimerange"
            align="right"
            unlink-panels
            range-separator="-"
            start-placeholder="Начало"
            end-placeholder="Конец"
            :picker-options="dateOptions"
          )
        .user__container(v-if="isAdmin")
          el-select(v-model="user")
            el-option(v-for="currentUser in balanceUsers" :value="currentUser" :label="currentUser" :key="`balance-user-${currentUser}`")
        .stage__container
          el-select(v-model="stage")
            el-option(v-for="(label, value) in stagesLocales" :value="value" :label="label" :key="`balance-stage-${value}`")
        el-button.request__btn(@click="loadBalances()") Запросить
      .balances__container
        .balances-info {{ `Всего ${balanceHistory.pagination.count || 0} изменений баланса, соответствующих условиям запроса` }}
        .balance-data
          el-table(
            v-loading="isLoading"
            :data="balances"
            style="width: 100%; font-size: 12px"
          )
            el-table-column(prop="username" label="Пользователь" width="200")
            el-table-column(prop="balance" label="Общий баланс" width="150")
            el-table-column(prop="balance_dep" label="Активный баланс" width="150")
            el-table-column(prop="balance_current" label="Текущий общий баланс" width="200")
            el-table-column(prop="balance_dep_current" label="Текущий активный баланс" width="200")
            el-table-column(prop="stage" label="Причина" width="200")
            el-table-column(label="Дополнительно" width="130")
              template(slot-scope="scope")
                .val(v-if="scope.row.height")
                  a(v-if="scope.row.tx_id" :href="`https://live.blockcypher.com/btc/tx/${scope.row.tx_id}`" target="_blank") {{ scope.row.height }}
                  span(v-else) {{ scope.row.height }}
            el-table-column(prop="created" label="Дата" width="150")
        .balance-pagination__container
          el-pagination(
            style="margin-top: 20px"
            v-if='hasPagination',
            :total='balanceHistory.pagination.count',
            :page-size="per_page",
            :current-page='balanceHistory.pagination.page',
            background,
            layout='prev, pager, next',
            @current-change='loadBalances',
          )
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { LOAD_BALANCE_CHANGES, LOAD_BALANCE_USERS } from "../../store/const/user";

export default {
  name: 'Stats',
  data() {
    return {
      date: '',
      dateOptions: {
        shortcuts: [{
          text: 'Сегодня',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setHours(0, 0, 0, 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Сутки',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        },  {
          text: 'Вчера',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setDate(start.getDate() - 1);
            start.setHours(0, 0, 0, 0);
            end.setDate(end.getDate() - 1);
            end.setHours(23, 59, 59, 999);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Неделя',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Месяц',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '3 месяца',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      stagesLocales: {
        order_create: 'Создание заявки',
        order_cancel: 'Отмена заявки',
        order_finish: 'Завершение заявки',
        deposit_check: 'Автоматическое пополнение',
        manual_change: 'Ручное снятие',
      },
      user: '',
      per_page: 50,
      stage: '',
    }
  },
  mounted() {
    if (this.isAdmin) {
      this.loadBalanceUsers();
    } else {
      this.user = this.username;
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'error',
    ]),
    ...mapGetters('user', [
      'balanceUsers',
      'balanceHistory',
      'isAdmin',
      'username',
    ]),
    isLoading() {
      return this.loading(LOAD_BALANCE_CHANGES) === 'loading';
    },
    isError() {
      return this.loading(LOAD_BALANCE_CHANGES) === 'error';
    },
    errorMessage() {
      return this.error(LOAD_BALANCE_CHANGES);
    },
    hasPagination() {
      return this.balanceHistory.pagination.count > this.per_page;
    },
    dates() {
      if (Array.isArray(this.date)) {
        const from = new Date(this.date[0]);
        const to = new Date(this.date[1]);
        from.setMinutes(from.getMinutes() - from.getTimezoneOffset()); // SET TO UTC 0
        to.setMinutes(to.getMinutes() - to.getTimezoneOffset()); // SET TO UTC 0
        from.setMinutes(from.getMinutes() - 180); // SET TO UTC +3
        to.setMinutes(to.getMinutes() -180); // SET TO UTC +3
        return {
          from: from.getTime(),
          to: to.getTime(),
        }
      } else {
        return null;
      }
    },
    balancesUser() {
      return this.balanceHistory.user;
    },
    balances() {
      return this.balanceHistory.balances.map((balance) => {
        const created = new Date(balance.created);
        let stage = this.stagesLocales[balance.stage] || balance.stage;
        if (balance.order_id) {
          stage += ` №${balance.order_id}`;
        }
        return {
          ...balance,
          created: created.toLocaleString(),
          stage,
        }
      });
    },
  },
  methods: {
    ...mapActions('user', {
      loadBalanceChanges: LOAD_BALANCE_CHANGES,
      loadBalanceUsers: LOAD_BALANCE_USERS,
    }),
    async loadBalances(page = 1) {
      await this.loadBalanceChanges({
        ...this.dates,
        user: this.user,
        page,
        per_page: this.per_page,
        stage: this.stage,
      });
    }
  }
};
</script>
<style scoped>
.stats__container {
  overflow-y: scroll;
}

.filters__container {
  display: flex;
  height: 40px;
  padding: 5px;
  background-color: #efefef;
}

.request__btn {
  margin-left: 5px;
}

.balances-info {
  padding: 8px;
  background-color: white;
  width: fit-content;
  margin: 2px 0;
  border-radius: 8px;
  color: #3a3ebb;
}
</style>
