<template lang="pug">
  .module__main
    .module-content__container
      .groups__container
        el-button(type="primary" @click="addNewGroup()") Новая группа
        .groups-list__container
          .group__container(v-for="(group, index) in groupsList" :key="`group-${group.isNewGroup ? 'new' : group.name}-${index}`")
            .group__name
              .title Название группы
              el-input(v-model="group.name" :disabled="!group.isNewGroup")
            .users__container
              .title Пользователи
              .user__container.exist(v-for="user in group.users" :key="`user-group-${group.isNewGroup ? 'new' : group.name}-${index}-user-${user}`")
                .user__name {{ user }}
                .user__btn(@click="removeMember(group, user)")
                  i.el-icon-remove.user-remove__btn
              .user__container
                el-input.new-user__input(v-model="group.newUser")
                .user__btn(@click="addMember(group)")
                  i.el-icon-plus.user-add__btn
            el-button.save-group__btn(v-if="group.isNewGroup" type="success" @click="saveNewGroup(group)") Сохранить группу
            el-button.save-group__btn(v-else type="danger" @click="startRemoveGroup(group)") Удалить группу
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  ADD_GROUP,
  ADD_MEMBER_TO_GROUP,
  CREATE_USER,
  EDIT_BALANCE,
  EDIT_USER,
  LOAD_GROUPS,
  LOAD_USERS_LIST, REMOVE_GROUP, REMOVE_MEMBER_FROM_GROUP
} from "../../store/const/user";

export default {
  name: 'Groups',
  data() {
    return {
      existGroups: [],
      newGroups: [],
    }
  },
  mounted() {
    this.loadGroups();
  },
  watch: {
    groups(val) {
      this.existGroups = val.map((group) => {
        return {
          ...group,
          newUser: '',
        }
      });
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'error',
    ]),
    ...mapGetters('user', [
      'groups'
    ]),
    isLoading() {
      return this.loading(LOAD_GROUPS) === 'loading';
    },
    groupsList() {
      return [...this.newGroups, ...this.existGroups];
    },
  },
  methods: {
    ...mapActions('user', {
      loadGroups: LOAD_GROUPS,
      addGroup: ADD_GROUP,
      removeGroup: REMOVE_GROUP,
      addMemberToGroup: ADD_MEMBER_TO_GROUP,
      removeMemberFromGroup: REMOVE_MEMBER_FROM_GROUP,
    }),
    addNewGroup() {
      this.newGroups.push({ name: '', users: [], newUser: '', isNewGroup: true });
    },
    async addMember(group) {
      if (group.newUser) {
        if (group.users.includes(group.newUser)) {
          return this.$message.error(`Группа уже содержит пользователя ${group.newUser}`);
        }
        let success = true;
        if (!group.isNewGroup) {
          const request = await this.addMemberToGroup({ group: group.name, user: group.newUser });
          if (request.success) {
            this.$message.success('Пользователь добавлен');
          } else {
            this.$message.error(`Ошибка добавления пользователя: ${request.message || 'Неизвестная ошибка'}`);
            success = false;
          }
        }

        if (success) {
          group.users.push(group.newUser);
          group.newUser = '';
        }
      }
    },
    async removeMember(group, user) {
      if (group.users.includes(user)) {
        let success = true;
        if (!group.isNewGroup) {
          const request = await this.removeMemberFromGroup({ group: group.name, user });
          if (request.success) {
            this.$message.success('Пользователь удален');
          } else {
            this.$message.error(`Ошибка удаления пользователя: ${request.message || 'Неизвестная ошибка'}`);
            success = false;
          }
        }

        if (success) {
          group.users.splice(group.users.indexOf(user), 1);
        }
      }
    },
    async saveNewGroup(group) {
      if (!group.name) {
        return this.$message.error(`Введите название группы`);
      }
      const request = await this.addGroup({ group: group.name, users: group.users });
      if (request.success) {
        this.$message.success('Группа добавлена');
        this.existGroups.push({ name: group.name, users: group.users, newUser: '' });
        this.newGroups = this.newGroups.filter((current) => current.name !== group.name);
      } else {
        this.$message.error(`Ошибка добавления группы: ${request.message || 'Неизвестная ошибка'}`);
      }
    },
    async startRemoveGroup(group) {
      this.$confirm(
          `Вы уверены, что хотите удалить группу ${group.name}?`,
          'Удаление группы'
      ).then(async () => {
        const request = await this.removeGroup({ group: group.name });
        if (request.success) {
          this.$message.success('Группа успешно удалена');
          if (group.isNewGroup) {
            this.newGroups = this.newGroups.filter((current) => current.name !== group.name);
          }  else {
            this.existGroups = this.existGroups.filter((current) => current.name !== group.name);
          }
        } else {
          this.$message.error(`Ошибка удаления группы: ${request.message || 'Неизвестная ошибка'}`);
        }
      }).catch(() => {
        this.$message.info('Удаление пользователя отменено');
      });
    },
  }
};
</script>
<style scoped>
.groups__container {
  overflow-y: scroll;
}
.groups-list__container {
  display: flex;
  flex-wrap: wrap;
}
.group__container {
  width: 300px;
  padding: 5px;
  border: 1px solid gray;
  margin: 5px;
}
.group__container .title {
  margin: 5px auto;
}
.user__btn {
  width: 45px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  border: 1px solid #c7c3c3;
  cursor: pointer;
}
.user__container {
  display: flex;
  align-items: center;
}
.user__container.exist {
  border-bottom: 1px dotted #c7c3c3;
  margin-bottom: 2px;
}
.user__name {
  flex: 1;
}
.save-group__btn {
  margin-top: 10px;
}
</style>
