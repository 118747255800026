<template lang="pug">
  .module__main
    .module-content__container
      .bins-list-add
        el-button(type="info" size="mini" @click="openBinAdd()") Добавить
      .bins-list__container
        .bins__grid
            .bin__container(v-for="bin in bins")
                span.bin-value {{ bin.bin }}:
                span.bin-type {{ types[bin.type] }}
                el-button(@click="removeBin(bin.bin)" type="danger" icon="el-icon-delete" size="mini" circle)
    el-dialog(:visible.sync="isBinAdding" :before-close="resetBinAdd" title="Новый BIN" width="30%")
      .dialog__container
        .dialog-add__input
          el-select(v-model="addingBin.type" placeholder="Банк")
            el-option(
              v-for="(type, key) in types"
              :key="key"
              :label="type"
              :value="key"
              )
        .dialog-add__input
          el-input(v-model="addingBin.bin" placeholder="BIN (первые 6 цифр карты)")
        .dialog-add__buttons
          el-button(type="success" @click="addBin()") Сохранить
          el-button(type="danger" @click="resetBinAdd()") Отменить
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { ADD_BIN, DELETE_BIN, LOAD_BINS_LIST } from "../../store/const/bins";

  export default {
    name: 'BinsList',
    data() {
      return {
        type: 'sber',
        types: {
          sber: 'Сбербанк',
          tinkoff: 'Tinkoff',
          raiffeisen: 'Райффайзен',
          alfa: 'Альфа-Банк',
        },
        addingBin: {
          type: 'sber',
          bin: '',
        },
        isBinAdding: false,
      }
    },
    mounted() {
      this.loadBins()
    },
    computed: {
      ...mapGetters([
        'loading',
        'error',
      ]),
      ...mapGetters('bins', [
        'bins',
      ]),
      isLoading() {
        return this.loading(LOAD_BINS_LIST) === 'loading';
      },
      isError() {
        return this.loading(LOAD_BINS_LIST) === 'error';
      },
      errorMessage() {
        return this.error(LOAD_BINS_LIST);
      },
    },
    methods: {
      ...mapActions('bins', {
        loadBinsList: LOAD_BINS_LIST,
        addNewBin: ADD_BIN,
        deleteBin: DELETE_BIN,
      }),
      async loadBins() {
        if (this.isLoading) {
          return;
        }
        await this.loadBinsList();
      },
      async removeBin(bin) {
        const request = await this.deleteBin({ bin });
        if (request.success) {
          this.$message.success('BIN успешно удален');
        } else {
          this.$message.error(`Ошибка удаления BIN: ${request.message || 'Неизвестная ошибка'}`);
        }
      },
      openBinAdd() {
        this.isBinAdding = true;
      },
      async addBin() {
        if (this.addingBin.bin.length !== 6) {
          this.$message.error('Некорректный BIN (длина BIN должна равняться 6 символам)');
        } else {
          const request = await this.addNewBin({ bin: this.addingBin });
          if (request.success) {
            this.$message.success('BIN успешно добавлен/обновлен');
            this.resetBinAdd();
            await this.loadBins();
          } else {
            this.$message.error(request.message);
          }
        }
      },
      resetBinAdd() {
        this.isBinAdding = false;
        this.addingBin = {
          type: 'sber',
            bin: '',
        };
      }
    }
  };
</script>
<style scoped>
  .bins-list__container {
      overflow-y: scroll;
      background-color: white;
      padding: 4px;
  }

  .bins__grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 12px;
  }

  .bins__pagination {
    height: 50px;
  }

  .bins-list-add {
    margin-bottom: 5px;
  }

  .bin__container {
      border: 1px solid #ccc;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .bin-value {
      font-weight: bold;
  }

  .bin-type {
      margin: auto 5px;
  }
</style>
