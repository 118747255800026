<template lang="pug">
  .module__main
    .module-content__container
      el-button.user-add__btn(type="primary" size="mini" @click="openUserAdding()") Добавить пользователя
      .table__container
        .table-list__container
          el-table(
            v-loading="isLoading"
            :data="formattedUsersList"
            style="width: 100%; font-size: 12px"
          )
            el-table-column(prop="name" label="Логин" width="100")
            el-table-column(prop="role" label="Роль" width="100")
              template(slot-scope="scope")
                span {{ roles[scope.row.role] || scope.row.role }}
            el-table-column(prop="api_key" label="Ключ API" width="120")
            el-table-column(label="Баланс" width="140")
              template(slot-scope="scope")
                .balance__container
                  .val {{ scope.row.balance }}
                  .balance-change__buttons
                    .balance-change__btn.background-success(@click="activateBalanceChange(scope.row, 'plus')") +
                    .balance-change__btn.background-danger(@click="activateBalanceChange(scope.row, 'minus')") -
            el-table-column(prop="balance_dep" label="Активный баланс" width="130")
            el-table-column(prop="balance_credit_limit" label="Лимит кредита" width="120")
            el-table-column(prop="card_percent_priority" label="Процент Сбер/Тинь" width="140")
            el-table-column(prop="card_percent_other" label="Процент C2C" width="120")
            el-table-column(label="Доступ API" width="100")
              template(slot-scope="scope")
                .user-active-change__btn(@click="changeUserActiveStatus(scope.row)" :class="scope.row.active ? 'active' : 'inactive'")
                  i.el-icon-check(v-if="scope.row.active")
                  i.el-icon-close(v-else)
            el-table-column(label="Трафик" width="100")
              template(slot-scope="scope")
                .user-active-change__btn(@click="changeUserWorkStatus(scope.row)" :class="scope.row.is_work ? 'active' : 'inactive'")
                  i.el-icon-check(v-if="scope.row.is_work")
                  i.el-icon-close(v-else)
            el-table-column(label="Сделки" width="80")
              template(slot-scope="scope")
                .user-active-change__btn(@click="changeUserShowDealInfoStatus(scope.row)" :class="scope.row.show_deals_info ? 'active' : 'inactive'")
                  i.el-icon-check(v-if="scope.row.show_deals_info")
                  i.el-icon-close(v-else)
            el-table-column(label="Приоритет" width="120")
              template(slot-scope="scope")
                .priority__container
                  el-input(v-model="scope.row.priority" type="number")
                  i.el-icon-refresh.refresh__btn(@click="changeUserPriority(scope.row.name, scope.row.priority)")
            el-table-column(label="Действия" width="100")
              template(slot-scope="scope")
                el-button(type="primary" size="mini" @click="openUserEditing(scope.row)") Изменить
    el-dialog(:visible.sync="isUserAdding" :before-close="resetUserEditing" :title="editingDialogTitle" width="50%")
      .user-add__container
        .user-add__input(v-if="isNewUserEditing")
          .title Имя пользователя
          el-input(v-model="editingUser.name" placeholder="Введите логин")
        .user-add__input(v-if="isNewUserEditing")
          .title Пароль
          el-input(v-model="editingUser.password" placeholder="Введите пароль" type="password")
        .user-add__input
          .title Роль
          el-select(v-model="editingUser.role")
            el-option(v-for="(label, role) in roles" :value="role" :label="label" :key="`user-add-role-${role}`")
        .user-add__input
          .title Обработчик
          el-select(v-model="editingUser.handler")
            el-option(v-for="(label, handler) in handlers" :value="handler" :label="label" :key="`user-add-handler-${handler}`")
        .user-add__input
          .title Кредитный лимит
          el-input(v-model="editingUser.balance_credit_limit" placeholder="Введите кредитный лимит" type="number")
        .user-add__input
          .title Процент Сбер/Тинь
          el-input(v-model="editingUser.card_percent_priority" placeholder="Введите процент Сбер/Тинь" type="number")
        .user-add__input
          .title Процент C2C
          el-input(v-model="editingUser.card_percent_other" placeholder="Введите процент C2C" type="number")
        .user-add__input
          .title Токен FX
          el-input(v-model="editingUser.finex_token" placeholder="Введите токен FX")
        .user-add__input
          .title Приватный ключ FX
          el-input(v-model="editingUser.finex_private_key" placeholder="Введите приватный ключ")
        .user-add__input
          .title Токен SD
          el-input(v-model="editingUser.saudi_token" placeholder="Введите токен SD")
        .user-add__input
          .title Кошелек для проверки
          el-input(v-model="editingUser.wallet" placeholder="Введите адрес кошелька")
        .user-add__input
          .title Сумма для проверки
          el-input(v-model="editingUser.wallet_deposit_check_amount" placeholder="Введите сумму" type="number")
        .user-add__input(v-if="editingUser.role !== 'trader'")
          .title Может добавлять заявки с панели
          el-checkbox(v-model="editingUser.canOpenOrdersViaPanel")
        .user-add__input(v-if="editingUser.role !== 'client'")
          .title Может устанавливать комиссию
          el-checkbox(v-model="editingUser.can_set_commission")
        .user-add__input(v-if="editingUser.role === 'trader' && editingUser.handler === 'finex'")
          .title Связные аккаунты Finex (через запятую, без пробелов)
          el-input(v-model="editingUser.finex_logins")
        .user-add__input
          .title Белый список IP-адресов для доступа по апи-ключу (через запятую, без пробелов)
          el-input(v-model="editingUser.api_token_ip_white_list")
        .user-add__buttons
          el-button(type="success" @click="addOrEditUser()") Сохранить
          el-button(type="danger" @click="resetUserEditing()") Отменить
    el-dialog(:visible.sync="isBalanceChanging" :before-close="resetBalanceChange" :title="balanceChangeTitle" width="30%")
      .balance-dialog__container
        .title Введите количество BTC
        el-input(type="number" v-model="balanceChange.change_value" placeholder="Количество BTC")
        .user-add__buttons
          el-button(type="success" @click="changeUserBalance()") {{ balanceChange.change_mode === 'plus' ? 'Добавить' : 'Убавить' }}
          el-button(type="danger" @click="resetBalanceChange()") Отменить
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { CREATE_USER, EDIT_BALANCE, EDIT_USER, LOAD_USERS_LIST } from "../../store/const/user";

  export default {
    name: 'UsersList',
    data() {
      return {
        roles: {
          superadmin: 'Суперадмин',
          admin: 'Админ',
          client: 'Клиент',
          trader: 'Трейдер',
        },
        handlers: {
          finex: 'FX',
        },
        isUserAdding: false,
        isUserEditing: false,
        editingUserId: null,
        editingUser: {
          name: '',
          password: '',
          role: 'client',
          handler: '',
          balance_credit_limit: 0,
          card_percent_priority: 0,
          card_percent_other: 0,
          finex_token: '',
          finex_private_key: '',
          saudi_token: '',
          canOpenOrdersViaPanel: false,
          can_set_commission: false,
          wallet: '',
          wallet_deposit_check_amount: 0,
          finex_logins: '',
          api_token_ip_white_list: '',
        },
        balanceChange: {
          user_id: null,
          user_name: '',
          change_mode: 'plus',
          change_value: 0,
        },
        isBalanceChanging: false,
        usersSortingRules: JSON.parse(localStorage.getItem('hconf_users_sorting_rules') || '{}'),
      }
    },
    mounted() {
      this.loadUsersList();
    },
    computed: {
      ...mapGetters([
        'loading',
        'error',
      ]),
      ...mapGetters('user', [
        'users'
      ]),
      isLoading() {
        return this.loading(LOAD_USERS_LIST) === 'loading';
      },
      isError() {
        return this.loading(LOAD_USERS_LIST) === 'error';
      },
      errorMessage() {
        return this.error(LOAD_USERS_LIST);
      },
      isNewUserEditing() {
        return !this.editingUserId;
      },
      editingDialogTitle() {
        return this.isNewUserEditing ? 'Добавление пользователя' : 'Изменение пользователя';
      },
      balanceChangeTitle() {
        return this.balanceChange.change_mode === 'plus'
          ? `Добавление баланса пользователю ${this.balanceChange.user_name}`
          : `Убавление баланса пользователя ${this.balanceChange.user_name}`;
      },
      formattedUsersList() {
        if (this.users.length > 0) {
          const usersList = this.users.map((user) => {
            return {
              ...user,
              priority: this.usersSortingRules[user.name] || 0,
            }
          });
          return usersList.sort((a, b) => b.priority - a.priority);
        } else {
          return this.users;
        }
      },
    },
    methods: {
      ...mapActions('user', {
        loadUsersList: LOAD_USERS_LIST,
        addNewUser: CREATE_USER,
        editUser: EDIT_USER,
        editBalance: EDIT_BALANCE,
      }),
      openUserAdding() {
        this.isUserAdding = true;
      },
      openUserEditing(user) {
        this.editingUser.role = user.role;
        this.editingUser.balance_credit_limit = user.balance_credit_limit;
        this.editingUser.card_percent_priority = user.card_percent_priority;
        this.editingUser.card_percent_other = user.card_percent_other;
        this.editingUser.finex_token = user.finex_token;
        this.editingUser.finex_private_key = user.finex_private_key;
        this.editingUser.saudi_token = user.saudi_token;
        this.editingUser.canOpenOrdersViaPanel = user.canOpenOrdersViaPanel;
        this.editingUser.can_set_commission = user.can_set_commission;
        this.editingUser.wallet = user.wallet;
        this.editingUser.wallet_deposit_check_amount = user.wallet_deposit_check_amount;
        this.editingUser.handler = user.handler;
        this.editingUser.finex_logins = user.finex_logins;
        this.editingUser.api_token_ip_white_list = (user.api_token_ip_white_list || []).join(',');
        this.editingUserId = user.id;
        this.isUserAdding = true;
      },
      resetUserEditing() {
        this.isUserAdding = false;
        this.editingUser.name = '';
        this.editingUser.password = '';
        this.editingUser.role = 'client';
        this.editingUser.balance_credit_limit = 0;
        this.editingUser.card_percent_priority = 0;
        this.editingUser.card_percent_other = 0;
        this.editingUser.finex_token = '';
        this.editingUser.finex_private_key = '';
        this.editingUser.saudi_token = '';
        this.editingUser.canOpenOrdersViaPanel = false;
        this.editingUser.can_set_commission = false;
        this.editingUser.wallet = '';
        this.editingUser.wallet_deposit_check_amount = 0;
        this.editingUser.handler = '';
        this.editingUser.finex_logins = '';
        this.editingUser.api_token_ip_white_list = '';
        this.editingUserId = null;
      },
      async addOrEditUser() {
        let request = {};
        if (this.isNewUserEditing) {
          if (!this.editingUser.name || !this.editingUser.password) {
            return this.$message.info('Необходимо заполнить все поля');
          } else if (this.editingUser.password.length < 7) {
            return this.$message.info('Минимальная длина пароля 7 символов');
          } else if (!this.editingUser.handler) {
            return this.$message.info('Необходимо указать обработчик');
          }
          request = await this.addNewUser(this.editingUser);
        } else {
          request = await this.editUser({
            id: this.editingUserId,
            data: {
              role: this.editingUser.role,
              balance_credit_limit: this.editingUser.balance_credit_limit,
              card_percent_priority: this.editingUser.card_percent_priority,
              card_percent_other: this.editingUser.card_percent_other,
              finex_token: this.editingUser.finex_token,
              finex_private_key: this.editingUser.finex_private_key,
              saudi_token: this.editingUser.saudi_token,
              canOpenOrdersViaPanel: this.editingUser.canOpenOrdersViaPanel,
              can_set_commission: this.editingUser.can_set_commission,
              wallet: this.editingUser.wallet,
              wallet_deposit_check_amount: this.editingUser.wallet_deposit_check_amount,
              handler: this.editingUser.handler,
              finex_logins: this.editingUser.finex_logins,
              api_token_ip_white_list: this.editingUser.api_token_ip_white_list,
            }
          });
        }

        if (request.success) {
          this.$message.success('Пользователь успешно добавлен');
          this.loadUsersList();
          this.resetUserEditing();
        } else {
          this.$message.error(`Ошибка добавления пользователя: ${request.message || 'Неизвестная ошибка'}`);
        }
      },
      async changeUserActiveStatus(user) {
        this.$confirm(
          `Вы уверены, что хотите ${user.active ? 'деактивировать' : 'активировать'} пользователя?`,
          'Изменение пользователя'
        ).then(async () => {
          const request = await this.editUser({ id: user.id, data: { active: !user.active } });
          if (request.success) {
            this.$message.success('Пользователь успешно изменен');
            this.loadUsersList();
            this.resetUserEditing();
          } else {
            this.$message.error(`Ошибка изменения пользователя: ${request.message || 'Неизвестная ошибка'}`);
          }
        }).catch(() => {
          this.$message.info('Изменение пользователя отменено');
        });
      },
      async changeUserWorkStatus(user) {
        this.$confirm(
          `Вы уверены, что хотите ${user.is_work ? 'отключить' : 'включить'} трафик пользователю?`,
          'Изменение пользователя'
        ).then(async () => {
          const request = await this.editUser({ id: user.id, data: { is_work: !user.is_work } });
          if (request.success) {
            this.$message.success('Пользователь успешно изменен');
            this.loadUsersList();
            this.resetUserEditing();
          } else {
            this.$message.error(`Ошибка изменения пользователя: ${request.message || 'Неизвестная ошибка'}`);
          }
        }).catch(() => {
          this.$message.info('Изменение пользователя отменено');
        });
      },
      async changeUserShowDealInfoStatus(user) {
        this.$confirm(
          `Вы уверены, что хотите ${user.is_work ? 'отключить' : 'включить'} отображение сделок для пользователя?`,
          'Изменение пользователя'
        ).then(async () => {
          const request = await this.editUser({ id: user.id, data: { show_deals_info: !user.show_deals_info } });
          if (request.success) {
            this.$message.success('Пользователь успешно изменен');
            this.loadUsersList();
            this.resetUserEditing();
          } else {
            this.$message.error(`Ошибка изменения пользователя: ${request.message || 'Неизвестная ошибка'}`);
          }
        }).catch(() => {
          this.$message.info('Изменение пользователя отменено');
        });
      },
      activateBalanceChange(user, mode) {
        this.balanceChange.user_id = user.id;
        this.balanceChange.user_name = user.name;
        this.balanceChange.change_value = 0;
        this.balanceChange.change_mode = mode;
        this.isBalanceChanging = true;
      },
      resetBalanceChange() {
        this.balanceChange = {
          user_id: null,
          user_name: '',
          change_mode: 'plus',
          change_value: 0,
        };
        this.isBalanceChanging = false;
      },
      async changeUserBalance() {
        this.$confirm(
          `Вы уверены, что хотите изменить баланс пользователя?`,
          'Изменение пользователя'
        ).then(async () => {
          if (!this.balanceChange.change_value) {
            this.$message.info(`Введите количество BTC для ${this.balanceChange.change_mode === 'plus' ? 'добавления' : 'убавление'}`);
            return;
          } else if (this.balanceChange.change_value < 0) {
            this.$message.info('Значение должно быть положительным');
            return;
          }
          const request = await this.editBalance({
            id: this.balanceChange.user_id,
            data: {
              mode: this.balanceChange.change_mode,
              value: this.balanceChange.change_value,
            }
          });
          if (request.success) {
            this.$message.success('Баланс успешно изменен');
            this.loadUsersList();
            this.resetBalanceChange();
          } else {
            this.$message.error(`Ошибка изменения баланса: ${request.message || 'Неизвестная ошибка'}`);
          }
        }).catch(() => {
          this.$message.info('Изменение баланса отменено');
        });
      },
      changeUserPriority(name, priority) {
        if (priority === '0') {
          delete this.usersSortingRules[name];
        } else {
          this.usersSortingRules[name] = parseInt(priority);
        }
        localStorage.setItem('hconf_users_sorting_rules', JSON.stringify(this.usersSortingRules));
        this.loadUsersList();
      },
    }
  };
</script>
<style scoped>
  .user-add__btn {
    width: 160px;
    margin-bottom: 10px;
  }

  .user-add__input {
    margin: 5px;
  }

  .user-add__input .title {
    margin-bottom: 5px;
  }

  .user-add__buttons {
    margin-top: 5px;
  }

  .user-active-change__btn {
    cursor: pointer;
  }

  .user-active-change__btn.active {
    color: green;
  }

  .user-active-change__btn.inactive {
    color: red;
  }

  .balance__container {
    display: flex;
    align-items: center;
  }

  .balance-change__buttons {
    margin-left: 10px;
  }

  .balance-change__btn {
    height: 15px;
    width: 15px;
    border: 1px solid #6a6a6a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px auto;
    color: white;
    cursor: pointer;
  }

  .priority__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .priority__container .refresh__btn {
    margin-left: 2px;
    cursor: pointer;
    font-size: 14px;
  }
</style>
