import Vue from 'vue'
import { HTTP_GET, HTTP_POST, HTTP_PUT } from '../const';
import {
  RESET_SETTINGS,
  LOAD_SETTINGS,
  UPDATE_SETTING,
  LOAD_USER_BALANCE, LOAD_TRADERS, EDIT_TRADER
} from "../const/settings";

export default {
  namespaced: true,
  state: {
    settings: {
      work_status: false,
      finex_ads_ids_card: [],
      finex_ads_ids_sbp: [],
      ad_wait_time: 60000,
      rate_deviation: 0,
    },
    userBalance: {
      balance: 0,
      balance_dep: 0,
      updated: '',
    },
    paymentSystems: [
      { alias: 'card', label: 'Карты' },
      { alias: 'sbp', label: 'СБП' },
    ],
    banks: [
      {
        "id": 1,
        "label": "Сбербанк",
        "alias": "sber"
      },
      {
        "id": 2,
        "label": "Тинькофф Банк",
        "alias": "tinkoff"
      },
      {
        "id": 3,
        "label": "Райффайзенбанк",
        "alias": "raiffeisen"
      },
      {
        "id": 4,
        "label": "ВТБ",
        "alias": "vtb"
      },
      {
        "id": 5,
        "label": "Альфа-Банк",
        "alias": "alfa"
      },
      {
        "id": 6,
        "label": "Газпромбанк",
        "alias": "gazprom"
      },
      {
        "id": 7,
        "label": "Банк Открытие",
        "alias": "otkrytie"
      },
      {
        "id": 8,
        "label": "Россельхозбанк",
        "alias": "rosselhoz"
      },
      {
        "id": 9,
        "label": "Совкомбанк",
        "alias": "sovkom"
      },
      {
        "id": 10,
        "label": "Росбанк",
        "alias": "rosbank"
      },
      {
        "id": 11,
        "label": "МТС банк",
        "alias": "mts"
      },
      {
        "id": 12,
        "label": "Банк Санкт-Петербург",
        "alias": "spb"
      },
      {
        "id": 13,
        "label": "Озон банк",
        "alias": "ozon"
      },
      {
        "id": 14,
        "label": "Уралсиб банк",
        "alias": "uralsib"
      },
      {
        "id": 15,
        "label": "МКБ банк",
        "alias": "mkb"
      },
      {
        "id": 16,
        "label": "Промсвязьбанк",
        "alias": "prom"
      },
      {
        "id": 17,
        "label": "Банк «УБРиР»",
        "alias": "ubrr"
      },
      {
        "id": 18,
        "label": "Хоум Банк",
        "alias": "home"
      },
      {
        "id": 19,
        "label": "ОТП-Банк",
        "alias": "otp"
      },
      {
        "id": 20,
        "label": "Ак Барс Банк",
        "alias": "akbars"
      },
      {
        "id": 21,
        "label": "Фора-Банк",
        "alias": "fora"
      },
      {
        "id": 22,
        "label": "Абсолют Банк",
        "alias": "absolute"
      },
      {
        "id": 23,
        "label": "РостФинанс Банк",
        "alias": "rostfinance"
      },
      {
        "id": 24,
        "label": "Юнистрим",
        "alias": "unistream"
      },
      {
        "id": 25,
        "label": "Транскапиталбанк",
        "alias": "transcapital"
      },
      {
        "id": 26,
        "label": "Почта Банк",
        "alias": "pochta"
      },
      {
        "id": 27,
        "label": "РНКБ Банк",
        "alias": "rncb"
      },
      {
        "id": 28,
        "label": "Генбанк",
        "alias": "genbank"
      },
      {
        "id": 29,
        "label": "Банк Русский Стандарт",
        "alias": "russtandart"
      },
      {
        "id": 30,
        "label": "ЭкспоБанк",
        "alias": "expobank"
      },
      {
        "id": 31,
        "label": "Банк Авангард",
        "alias": "avangard"
      },
      {
        "id": 32,
        "label": "ИнтерПрогрессБанк",
        "alias": "interprogress"
      },
      {
        "id": 33,
        "label": "Банк Дом.Рф",
        "alias": "domrf"
      },
      {
        "id": 34,
        "label": "Яндекс банк",
        "alias": "yandex"
      },
      {
        "id": 35,
        "label": "РЕСО Кредит",
        "alias": "resocredit"
      },
      {
        "id": 36,
        "label": "Кредит Европа Банк",
        "alias": "crediteurope"
      },
      {
        "id": 37,
        "label": "БКС Банк",
        "alias": "bksbank"
      },
      {
        "id": 38,
        "label": "Ренессанс",
        "alias": "renessans"
      },
      {
        "id": 39,
        "label": "ЮниКредит",
        "alias": "unicredit"
      },
      {
        "id": 40,
        "label": "СИТИбанк",
        "alias": "citybank"
      },
      {
        "id": 41,
        "label": "ВБРР",
        "alias": "vbrr"
      },
      {
        "id": 42,
        "label": "Новиком",
        "alias": "novicom"
      },
      {
        "id": 43,
        "label": "Банк Зенит",
        "alias": "zenit"
      },
      {
        "id": 44,
        "label": "Цифра Банк",
        "alias": "tsyfra"
      },
      {
        "id": 45,
        "label": "СургутНефтегаз",
        "alias": "surgutneftegaz"
      },
      {
        "id": 46,
        "label": "Морской банк",
        "alias": "morskoy"
      },
      {
        "id": 47,
        "label": "Металлинвестбанк",
        "alias": "metalinvestbank"
      },
    ],
  },
  getters: {
    settings: state => state.settings,
    positions: state => state.positions,
    userBalance: state => state.userBalance,
    banks: state => state.banks,
    paymentSystems: state => state.paymentSystems,
  },
  actions: {
    [LOAD_SETTINGS]: async ({ dispatch }) => {
      await dispatch(HTTP_GET, {
        method: LOAD_SETTINGS,
        namespace: 'settings',
      }, { root: true });
    },
    [UPDATE_SETTING]: async ({ dispatch }, data) => {
      const request = await dispatch(HTTP_PUT, {
        method: UPDATE_SETTING,
        namespace: 'settings',
        no_commit: true,
        body: data,
      }, { root: true });
      if (request && request.data) {
        return request.data;
      } else {
        return { success: false, message: 'Bad request' };
      }
    },
    [LOAD_USER_BALANCE]: async ({ dispatch }) => {
      await dispatch(HTTP_GET, {
        method: LOAD_USER_BALANCE,
        namespace: 'settings',
      }, { root: true });
    },
    [LOAD_TRADERS]: async ({ dispatch }) => {
      const request = await dispatch(HTTP_GET, {
        method: LOAD_TRADERS,
        namespace: 'settings',
        no_commit: true,
      }, { root: true });
      if (request && request.data) {
        return request.data;
      } else {
        return { success: false, message: 'Bad request' };
      }
    },
    [EDIT_TRADER]: async ({ dispatch }, { name, handler, changes, payment_system }) => {
      const request = await dispatch(HTTP_PUT, {
        method: EDIT_TRADER,
        namespace: 'settings',
        replace: { name },
        body: { handler, changes, payment_system },
        no_commit: true,
      }, { root: true });
      if (request && request.data) {
        return request.data;
      } else {
        return { success: false, message: 'Bad request' };
      }
    },
  },
  mutations: {
    [LOAD_SETTINGS]: (state, { data }) => {
      Vue.set(state, 'settings', data);
    },
    [LOAD_USER_BALANCE]: (state, { data }) => {
      Vue.set(state.userBalance, 'balance', data.balance);
      Vue.set(state.userBalance, 'balance_dep', data.balance_dep);
      const updated = new Date();
      Vue.set(state.userBalance, 'updated', updated.toLocaleTimeString());
    },
    [RESET_SETTINGS]: (state) => {
      Vue.set(state, 'userBalance', {
        balance: 0,
        balance_dep: 0,
        updated: '',
      });
    },
  },
};
