/**
 * Проверка существования и актуальности токена
 * @param token
 * @returns {boolean}
 */
export function isActiveToken(token) {
    if(!token || token === ''){
        return false;
    }
    return true;
}

export const AuthMixin = {
    methods: {
        isActiveToken
    }
};
