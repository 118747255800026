<template lang="pug">
  .module__main
    .module-content__container(v-loading="isLoading")
      .stats__container
        .filters__container
          .date__container
            el-date-picker(
              v-model="date"
              type="datetimerange"
              range-separator="-"
              start-placeholder="От"
              end-placeholder="До"
              :picker-options="dateOptions"
            )
            el-select(v-model="statsBy")
              el-option(label="По времени создания" value="created" key="stats-by-created")
              el-option(label="По времени завершения" value="finished" key="stats-by-finished")
          el-button.request__btn(:disabled="!dates" @click="requestStats()") Запросить
        .stats-details__container
          .stats-user__container(v-for="(stats, user) in stats")
            .stats-user-details__name {{ user }}
            .stat-user-details__additional
              .stats-user-csv(@click="startDownloadCsvStats(user)") CSV
            .stats-user-details__block
              .details__title Количество заявок:
              .details__value {{ stats.orders_amount }}
            .stats-user-details__block
              .details__title Сумма заявок:
              .details__value {{ `${stats.fiat_amount} р. (${stats.crypto_amount} BTC)` }}
            .stats-user-details__block
              .details__title Количество завершенных:
              .details__value {{ `${stats.orders_amount_finished} (${stats.finished_percent}%)` }}
            .stats-user-details__block
              .details__title Сумма завершенных:
              .details__value {{ `${stats.fiat_amount_finished} р. (${stats.crypto_amount_finished} BTC)` }}
            .stats-user-details__block(v-if="isAdmin")
              .details__title Сумма комиссии:
              .details__value {{ `${stats.commission_finished} р.` }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { LOAD_CSV_STATS, LOAD_STATS } from "../../store/const/user";

export default {
  name: 'Stats',
  data() {
    return {
      date: '',
      statsBy: 'created',
      dateOptions: {
        shortcuts: [{
          text: 'Сегодня',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setHours(0, 0, 0, 0);
            end.setHours(23, 59, 59, 999);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Сутки',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setDate(start.getDate() - 1);
            picker.$emit('pick', [start, end]);
          }
        },  {
          text: 'Вчера',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setDate(start.getDate() - 1);
            start.setHours(0, 0, 0, 0);
            end.setDate(end.getDate() - 1);
            end.setHours(23, 59, 59, 999);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Неделя',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Месяц',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '3 месяца',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'error',
    ]),
    ...mapGetters('user', [
      'stats',
      'isAdmin'
    ]),
    isLoading() {
      return this.loading(LOAD_STATS) === 'loading';
    },
    isError() {
      return this.loading(LOAD_STATS) === 'error';
    },
    errorMessage() {
      return this.error(LOAD_STATS);
    },
    dates() {
      if (Array.isArray(this.date)) {
        const from = new Date(this.date[0]);
        const to = new Date(this.date[1]);
        from.setMinutes(from.getMinutes() - from.getTimezoneOffset()); // SET TO UTC 0
        to.setMinutes(to.getMinutes() - to.getTimezoneOffset()); // SET TO UTC 0
        from.setMinutes(from.getMinutes() - 180); // SET TO UTC +3
        to.setMinutes(to.getMinutes() -180); // SET TO UTC +3
        return {
          from: from.getTime(),
          to: to.getTime(),
        }
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions('user', {
      loadStats: LOAD_STATS,
      loadCsvStats: LOAD_CSV_STATS,
    }),
    async requestStats() {
      await this.loadStats({ ...this.dates, stats_by: this.statsBy });
    },
    async startDownloadCsvStats(user) {
      await this.loadCsvStats({ ...this.dates, user, stats_by: this.statsBy });
    },
  }
};
</script>
<style scoped>
.stats__container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.filters__container {
  display: flex;
  padding: 5px;
  background-color: #efefef;
}

.request__btn {
  margin-left: 5px;
}

.stats-details__container {
  display: flex;
  margin: 5px 0;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  height: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  overflow-y: scroll;
}

.stats-user__container {
  width: 400px;
  padding: 5px;
  background-color: #efefef;
  border-radius: 4px;
  border: 1px solid #858585;
  margin: 5px;
  height: fit-content;
  position: relative;
}

.stats-user-details__name {
  font-weight: bold;
  margin-bottom: 5px;
  text-shadow: 1px 1px #dddddd;
  color: #303133;
  text-align: center;
}

.stats-user-details__block {
  display: flex;
}

.details__title {
  font-weight: 500;
  margin-right: 5px;
}

.stat-user-details__additional {
  display: flex;
  padding: 5px;
  position: absolute;
  right: 0;
  top: 0;
  font-style: italic;
  font-weight: bold;
  font-size: 11px;
  color: #7620ff;
}

.stats-user-csv {
  cursor: pointer;
  border: 1px solid #7620ff;
  background-color: #ddd9d9;
  padding: 5px;
  margin: auto 2px;
}

.stats-user-csv:hover {
  background-color: #b8b8b8;
}
</style>
