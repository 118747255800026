<template lang="pug">
  .module__main
    .module-content__container(v-loading="isLoading")
      .settings__container
        .setting__container
          .setting__title Статус работы
          el-switch(v-model="currentSettings.work_status" @change="updateWorkStatus()")
        .setting__container
          .setting__title ID объявлений FX (Карты)
          .settings-ads-ids__container
            .settings-ads-ids__id(v-for="id in currentSettings.finex_ads_ids_card" :key="`fx-ad-id-card-${id}`")
              .val {{ id }}
              el-button(@click="removeAdId(id, 'finex', 'card')" type="danger" icon="el-icon-delete" size="mini" circle)
          .settings-ads-ids__add
            el-input(type="number" placeholder="Добавить новый" v-model="finexNewAdIdcard")
            el-button(@click="addNewAdId('finex', 'card')" type="info" icon="el-icon-plus" size="mini" circle)
        .setting__container
          .setting__title ID объявлений FX (СБП)
          .settings-ads-ids__container
            .settings-ads-ids__id(v-for="id in currentSettings.finex_ads_ids_sbp" :key="`fx-ad-id-sbp-${id}`")
              .val {{ id }}
              el-button(@click="removeAdId(id, 'finex', 'sbp')" type="danger" icon="el-icon-delete" size="mini" circle)
          .settings-ads-ids__add
            el-input(type="number" placeholder="Добавить новый" v-model="finexNewAdIdsbp")
            el-button(@click="addNewAdId('finex', 'sbp')" type="info" icon="el-icon-plus" size="mini" circle)
        .setting__container
          .setting__title Время ожидания объявления (сек)
          .settings-ads-ids__add
            .ad-wait-time__input
              el-input(type="number" v-model="currentSettings.ad_wait_time")
            el-button(v-if="canUpdateAdWaitTime" @click="updateAdWaitTime()" type="info" icon="el-icon-refresh" size="mini" circle)
        .setting__container
          .setting__title Курс (% от бинанса)
          .settings-ads-ids__add
            .ad-wait-time__input
              el-input(type="number" v-model="currentSettings.rate_deviation")
            el-button(v-if="canUpdateRateDeviation" @click="updateRateDeviation()" type="info" icon="el-icon-refresh" size="mini" circle)
        .setting__container(v-if="currentSettings.handlers_priority")
          .setting__title Приоритет обработчиков
          .settings-handlers__container
            .handler__block(v-for="(handler, index) in currentSettings.handlers_priority" :key="`handler-priority-${handler}`")
              .handler__name {{ handler }}
              .handler__btns
                i.el-icon-arrow-up(v-if="index !== 0" @click="swapHandlers(index, index - 1)")
                i.el-icon-arrow-down(v-if="index !== currentSettings.handlers_priority.length - 1" @click="swapHandlers(index, index + 1)")
            el-button.handler-btn(@click="updateHandlersPriority()" type="success" size="mini") Обновить
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { LOAD_SETTINGS, UPDATE_SETTING } from "../../store/const/settings";

export default {
  name: 'Settings',
  data() {
    return {
      currentSettings: {
        work_status: false,
        finex_ads_ids_card: [],
        finex_ads_ids_sbp: [],
        ad_wait_time: 60,
        rate_deviation: 0,
        handlers_priority: [],
      },
      finexNewAdIdcard: '',
      finexNewAdIdsbp: '',
    }
  },
  async mounted() {
    await this.loadSettings();
  },
  watch: {
    settings(val) {
      this.currentSettings = { ...val };
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'error',
    ]),
    ...mapGetters('settings', [
      'settings',
    ]),
    isLoading() {
      return this.loading(LOAD_SETTINGS) === 'loading';
    },
    isError() {
      return this.loading(LOAD_SETTINGS) === 'error';
    },
    errorMessage() {
      return this.error(LOAD_SETTINGS);
    },
    hasPagination() {
      return this.bins.pagination.count > this.parts_count * this.part_size;
    },
    canUpdateAdWaitTime() {
      return parseInt(this.currentSettings.ad_wait_time) !== parseInt(this.settings.ad_wait_time);
    },
    canUpdateRateDeviation() {
      return parseFloat(this.currentSettings.rate_deviation) !== parseInt(this.settings.rate_deviation);
    },
  },
  methods: {
    ...mapActions('settings', {
      loadSettings: LOAD_SETTINGS,
      updateSetting: UPDATE_SETTING,
    }),
    removeAdId(id, handler, payment_system) {
      const allowedHandlers = ['finex'];
      if (!allowedHandlers.includes(handler)) {
        return this.$message.warning(`Неизвестный обработчик: ${handler}`);
      }
      const ids = this.settings[`${handler}_ads_ids_${payment_system}`].filter((current) => current !== id);
      this.requestSettingUpdate(`${handler}_ads_ids_${payment_system}`, ids);
    },
    async requestSettingUpdate(name, setting) {
      const request = await this.updateSetting({ name, setting });
      if (request.success) {
        this.$message.success('Настройки успешно обновлены');
        await this.loadSettings();
        this.currentSettings[name] = setting;
        return true;
      } else {
        this.$message.error(`Ошибка обновления настроек: ${request.message || 'Неизвестная ошибка'}`);
        return false;
      }
    },
    addNewAdId(handler, payment_system) {
      if (this.settings[`${handler}_ads_ids_${payment_system}`].includes(parseInt(this[`${handler}NewAdId${payment_system}`]))) {
        this.$message.info('Введённый ID уже имеется в списке объявлений');
      } else {
        const ids = [...this.settings[`${handler}_ads_ids_${payment_system}`], parseInt(this[`${handler}NewAdId${payment_system}`])];
        const isUpdateSuccess = this.requestSettingUpdate(`${handler}_ads_ids_${payment_system}`, ids);
        if (isUpdateSuccess) {
          this[`${handler}NewAdId${payment_system}`] = '';
        }
      }
    },
    updateAdWaitTime() {
      this.requestSettingUpdate('ad_wait_time', parseInt(this.currentSettings.ad_wait_time));
    },
    updateRateDeviation() {
      this.requestSettingUpdate('rate_deviation', parseFloat(this.currentSettings.rate_deviation));
    },
    updateWorkStatus() {
      const isUpdateSuccess = this.requestSettingUpdate('work_status', this.currentSettings.work_status);
      if (!isUpdateSuccess) {
        this.currentSettings.work_status = !this.currentSettings.work_status;
      }
    },
    updateHandlersPriority() {
      this.requestSettingUpdate('handlers_priority', this.currentSettings.handlers_priority);
    },
    swapHandlers(oldIndex, newIndex) {
      const newHandlersArray = [...this.currentSettings.handlers_priority];
      const newIndexValue = this.currentSettings.handlers_priority[newIndex];
      newHandlersArray[newIndex] = newHandlersArray[oldIndex];
      newHandlersArray[oldIndex] = newIndexValue;
      this.currentSettings.handlers_priority = [...newHandlersArray];
    },
  }
};
</script>
<style scoped>
.settings__container {
    display: flex;
    flex-wrap: wrap;
}

.setting__container {
    width: 240px;
    padding: 10px;
    background-color: white;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px #9f9f9f
}

.setting__title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
}

.settings-ads-ids__container {
    overflow-y: scroll;
    max-height: 200px;
}

.settings-ads-ids__id {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.settings-ads-ids__id .val {
    margin-right: 5px;
}

.settings-ads-ids__add {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.settings-ads-ids__add button {
    margin-left: 5px;
}

.handler__block {
    display: flex;
    padding: 4px;
    justify-content: space-between;
    border-bottom: 1px dotted #909399;
}

.handler__btns i {
    cursor: pointer;
}

.handler__btns i:hover {
    color: red;
}

.handler-btn {
    margin-top: 8px;
}
</style>
