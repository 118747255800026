import cfg from '../store/config';
import CryptoJS from 'crypto-js';

const encryption = {
  methods: {
    encrypt(data) {
      return CryptoJS.AES.encrypt(data, cfg.encryptionKey).toString();
    },
    decrypt(data) {
      const bytes =  CryptoJS.AES.decrypt(data, cfg.encryptionKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    }
  }
};

export default encryption
