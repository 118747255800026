import Vue from 'vue'
import { HTTP_DELETE, HTTP_GET, HTTP_POST } from '../const';
import { ADD_BIN, DELETE_BIN, LOAD_BINS_LIST } from "../const/bins";

export default {
  namespaced: true,
  state: {
    bins: [],
  },
  getters: {
    bins: state => state.bins,
  },
  actions: {
    [LOAD_BINS_LIST]: async ({ dispatch }) => {
      await dispatch(HTTP_GET, {
        method: LOAD_BINS_LIST,
        namespace: 'bins',
      }, { root: true });
    },
    [ADD_BIN]: async ({ dispatch }, { bin }) => {
      const request = await dispatch(HTTP_POST, {
        method: ADD_BIN,
        namespace: 'bins',
        body: { bin },
        no_commit: true,
      }, { root: true });
      if (request && request.data) {
        return request.data;
      } else {
        return { success: false, message: 'Bad reqest' };
      }
    },
    [DELETE_BIN]: async ({ dispatch, commit }, { bin }) => {
      const request = await dispatch(HTTP_DELETE, {
        method: DELETE_BIN,
        namespace: 'bins',
        body: { bin },
      }, { root: true });
      if (request && request.data) {
        commit(DELETE_BIN, bin);
        return request.data;
      } else {
        return { success: false, message: 'Bad reqest' };
      }
    },
  },
  mutations: {
    [LOAD_BINS_LIST]: (state, { data }) => {
      Vue.set(state, 'bins', data);
    },
    [DELETE_BIN]: (state, bin) => {
      const bins = state.bins.filter((current) => current.bin !== bin);
      Vue.set(state, 'bins', bins);
    },
  },
};
