<template lang="pug">
  .ads-list__container(v-loading="isLoading")
    i.el-icon-refresh.update__icon(@click="loadAds()")
    .ads-list__block
      .ad__container(v-for="ad in adsList" @click="chooseAd(ad)" :class="{ active: activeAd.id }")
        .ad-info
          .title Курс
          .val {{ ad.price }}
        .ad-info
          .title Минимальная сумма
          .val {{ ad.min }}
        .ad-info
          .title Максимальная сумма
          .val {{ ad.max }}
        .ad-info
          .title Банки
          .val {{ getBanksList(ad) }}
    .ad-sum__container(v-if="activeAd.id")
      .ad-sum__input
        el-input(type="number" v-model="fiat_amount_adding")
      .input__buttons
        .input__btn(@click="setFiatAmountAdding('max')") MAX
        .input__btn(@click="setFiatAmountAdding('min')") MIN
      el-button.add__btn(type="success" :disabled="!fiat_amount_adding" @click="addDealToOrder()") Добавить
    .ads__info(v-if="!adsAvailable") Нет доступных объявлений
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { ADD_AD, LOAD_ADS_LIST } from "../../store/const/orders";

  export default {
    name: 'AdsList',
    props: ['id', 'fiat_amount', 'fiat_amount_filled', 'fiat_amount_active', 'source'],
    data() {
      return {
        fiat_amount_adding: 0,
        ad_id: null,
        activeAd: {},
      }
    },
    mounted() {
      this.loadAds();
    },
    computed: {
      ...mapGetters([
        'loading',
        'error',
      ]),
      ...mapGetters('orders', [
        'ads'
      ]),
      isLoading() {
        return this.loading(LOAD_ADS_LIST) === 'loading';
      },
      isError() {
        return this.loading(LOAD_ADS_LIST) === 'error';
      },
      errorMessage() {
        return this.error(LOAD_ADS_LIST);
      },
      adsList() {
        return this.activeAd.id ? [this.activeAd] : this.ads;
      },
      orderFiatAmountLeft() {
        return this.fiat_amount - this.fiat_amount_filled - this.fiat_amount_active;
      },
      adsAvailable() {
        return this.ads.length > 0;
      }
    },
    methods: {
      ...mapActions('orders', {
        loadAdsList: LOAD_ADS_LIST,
        addAd: ADD_AD,
      }),
      async loadAds() {
        if (this.isLoading) {
          return;
        }
        await this.loadAdsList();
      },
      getBanksList(ad) {
        const bankNames = ad.banks.map((bank) => bank.title);
        return bankNames.join(', ');
      },
      chooseAd(ad) {
        this.fiat_amount_adding = '';
        this.activeAd = this.activeAd.id ? {} : ad;
      },
      setFiatAmountAdding(amount) {
        if (amount === 'max') {
          this.fiat_amount_adding = Math.min(this.orderFiatAmountLeft, parseInt(this.activeAd.max));
        } else {
          this.fiat_amount_adding = Math.min(this.orderFiatAmountLeft, parseInt(this.activeAd.min));
        }
      },
      async addDealToOrder() {
        this.$confirm(`Вы уверены, что хотите открыть сделку на сумму ${this.fiat_amount_adding} р. по курсу ${this.activeAd.price}?`, 'Подтверждение').then(async () => {
          const request = await this.addAd({
            id: this.id,
            data: {
              source: this.source,
              ad_id: this.activeAd.id,
              fiat_amount: parseInt(this.fiat_amount_adding),
              price: parseInt(this.activeAd.price),
            }
          });
          if (request.success) {
            this.$message.success('Сделка успешно добавлена к заявке');
            this.$emit('updateOrders');
          } else {
            this.$message.error(`Ошибка добавления сделки: ${ request.message || 'Неизвестная ошибка' }`);
          }
        }).catch(() => {
          this.$message.info('Открытие сделки отменено');
        })
      }
    }
  };
</script>
<style scoped>
  .ads-list__block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .ad__container {
    width: 240px;
    height: 240px;
    margin: 5px;
    padding: 5px;
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    cursor: pointer;
  }

  .ad__container.active {
    border: 1px solid #126412;
  }

  .ad-info .val {
    word-break: break-word;
  }

  .ad-sum__container {
    width: 240px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
  }

  .ad-sum__input {
    width: 200px;
  }

  .input__buttons {
    margin-left: 5px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .input__btn {
    border: 1px dotted lightgrey;
    padding: 1px;
    cursor: pointer;
  }

  .add__btn {
    margin-top: 5px;
  }

  .update__icon {
    padding: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  .update__icon:hover {
    color: #101527;
  }

  .ads__info {
    padding: 5px;
    background-color: #ededed;
    width: fit-content;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    text-align: center;
    margin: auto;
  }
</style>
