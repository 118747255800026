<template lang="pug">
  .sessions__container
    .sessions__list
      .session__container(v-for="session in sessions")
        .session__name(@click="startChangeSession(session)") {{ session.username }}
        i.el-icon-delete.session-delete__btn(@click="startDeleteSession(session)")
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { AUTH_LOGIN, CHANGE_SESSION, DELETE_SESSION } from '../../store/const/user';

export default {
  name: 'SessionPick',
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'loading',
      'error',
    ]),
    ...mapGetters('user', [
      'sessions',
    ]),
    isLoading() {
      return this.loading(AUTH_LOGIN) === 'loading';
    },
    isError() {
      return this.loading(AUTH_LOGIN) === 'error';
    },
    errorMessage() {
      return this.error(AUTH_LOGIN);
    },
  },
  methods: {
    ...mapActions('user', {
      changeSession: CHANGE_SESSION,
      deleteSession: DELETE_SESSION,
    }),
    startChangeSession(session) {
      this.changeSession(session);
    },
    startDeleteSession(session) {
      this.$confirm('Вы уверены, что хотите удалить сессию?').then(() => {
        this.deleteSession(session);
      }).catch(() => {
        this.$message.info('Отмена удаления сессии');
      })
    },
  },
};
</script>
<style>
.sessions__list {
  width: 250px;
  align-items: center;
  margin: auto;
  padding: 5px;
  background-color: #dddddd;
  border-radius: 5px;
}

.session__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  background-color: #cdcdcd;
  border: 1px solid #afafaf;
  border-radius: 2px;
  cursor: pointer;
  margin: 5px auto;
}

.session__container:hover {
  background-color: #afafaf;
}

.session__name {
  flex: 1;
}

.session-delete__btn {
  color: #dd2525;
}
.session-delete__btn:hover {
  color: #a31515;
}
</style>
