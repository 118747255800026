<template lang="pug">
  .module__main
    .module-content__container
      .filters__container
        .active-status__container
          el-select(v-model="activeMode" @change="loadOrders()")
            el-option(v-for="option in activeOptions" :key="`active-option-${option.key}`" :label="option.locale" :value="option.key")
        .new-order__btn(v-if="isCanOpenNewOrder")
          el-button(type="success" @click="activateOrderAdd()") Новая заявка
        .search__container
          el-select(v-model="searchMode" @change="updateDefaultSearchMode()")
            el-option(v-for="(mode, key) in searchModes" :key="`search-mode-${key}`" :label="mode.locale" :value="key")
          el-input(v-model="searchValue" :disabled="!searchMode" :placeholder="searchModes[searchMode] ? searchModes[searchMode].title : 'Поиск'")
            i.el-input__icon.el-icon-search(slot="suffix" @click="searchOrder()")
          i.el-icon-close.search-reset__btn(v-if="searchLoaded" @click="resetSearch()")
      .stats__container
        .stats__title Статистика
        .stats-content__container(v-if="showStats")
          .stats-content__block
            .stats-content__title Количество заявок
            .stats-content__data
              .stats-content__val(v-for="(val, i) in stats.amounts" :key="`stats-amounts-key-${i}`")
                .stats-content-val__title {{ `${val.title}:` }}
                .stats-content-val__value {{ val.value }}
          .stats-content__block
            .stats-content__title Суммы заявок
            .stats-content__data
              .stats-content__val(v-for="(val, i) in stats.sums" :key="`stats-sums-key-${i}`")
                .stats-content-val__title {{ `${val.title}:` }}
                .stats-content-val__value {{ val.value }}
          .stats-content__block(v-if="stats.showTradersStats")
            .stats-content__title Трейдеры
            .stats-content__data
              .stats-content__val(v-for="(val, i) in stats.traders" :key="`stats-traders-key-${i}`")
                .stats-content-val__title {{ `${val.title}:` }}
                .stats-content-val__value {{ val.value }}
        i.stats-handle__btn(:class="showStats ? 'el-icon-caret-top' : 'el-icon-caret-bottom'" @click="changeStatsShowStatus()")
      .table__container
        .table-list__container
          el-table(
            v-loading="isLoading"
            :data="formattedOrders"
            style="width: 100%; font-size: 12px",
            :row-class-name="tableRowClassName"
          )
            el-table-column(v-if="!isTrader" prop="source" label="Источник" width="100")
              template(v-slot:default="scope")
                i.copy__icon.el-icon-document-copy(@click="copyOrderInfo(scope.row)")
                span(v-if="scope.row.source" :class="{ 'color-success': scope.row.is_owner }") {{ scope.row.source }}
            el-table-column(v-if="!isTrader" prop="id" label="ID" width="90")
              template(v-slot:default="scope")
                span {{ scope.row.id }}
            el-table-column(label="Фиат" width="120")
              template(v-slot:default="scope")
                .fiat-amount__container
                  .val {{ `${scope.row.fiat_amount_filled}/${scope.row.fiat_amount}` }}
                  .fiat-add__btn(v-if="scope.row.isCanAddDeal" @click="openDealAdd(scope.row)")
                    el-button(type="primary" icon="el-icon-plus" size="mini")
            el-table-column(v-if="!isTrader" prop="btc_amount" label="BTC" width="120")
            el-table-column(v-if="!isTrader" prop="rate" label="Курс" width="120")
            el-table-column(v-if="isAdmin || isTrader" label="Сделки" width="180")
              template(v-slot:default="scope")
                .deals__container(@click="openDealsList(scope.row.id)")
                  .deal__container(v-for="deal in scope.row.deals" :style="getDealRowStyle(deal)")
                    .deal-val
                      span {{ handlersPseodos[deal.handler] || deal.handler }}
                      span {{ deal.offer }}
                    .deal-val
                      span {{ `ID: ${deal.deal_id}` }}
                      span {{ `${deal.fiat_amount}р.` }}
            el-table-column(prop="status" label="Статус" width="180")
              template(v-slot:default="scope")
                .val {{ statuses[scope.row.status] || scope.row.status }}
            el-table-column(label="Реквизит" width="170")
              template(v-slot:default="scope")
                .val {{ scope.row.requisites }}
                .val {{ scope.row.payment_system }}
                .val(v-if="scope.row.bank") {{ scope.row.bank }}
            el-table-column(v-if="isAdmin || isTrader" prop="commission" label="Комиссия" width="100")
            el-table-column(v-if="isAdmin || isSupport" label="Пометки" width="100")
              template(v-slot:default="scope")
                .notes__container
                  i.note-icon.el-icon-warning-outline(
                    :class="{ 'color-danger': scope.row.notes.problem }"
                    :title="scope.row.notes.problem ? 'Отмечена проблемной' : 'Отметить проблемной'"
                    @click="handleOrderNote(scope.row, 'problem')"
                  )
                  i.note-icon.el-icon-coin(
                    :class="{ 'color-success': scope.row.notes.paid }"
                    :title="scope.row.notes.problem ? 'Отмечена оплаченной' : 'Отметить оплаченной'"
                    @click="handleOrderNote(scope.row, 'paid')"
                  )
            el-table-column(label="Дата" width="150")
              template(v-slot:default="scope")
                .val Создана: {{ scope.row.created }}
                .val Обновлена: {{ scope.row.updated }}
                .val(v-if="scope.row.finished") Завершена: {{ scope.row.finished }}
            el-table-column(label="Действия" width="150")
              template(v-slot:default="scope")
                .action-buttons__container
                  el-button.action__btn(v-if="scope.row.isCanFinish" @click="startFinishOrder(scope.row.id, scope.row.source)" type="success" size="mini") Завершить
                  el-button.action__btn(v-if="scope.row.isCanRenew" @click="startRenewOrder(scope.row.id, scope.row.source)" type="warning" size="mini") Перезапустить
                  el-button.action__btn(v-if="scope.row.isCanCancel" @click="startCancelOrder(scope.row.id, scope.row.source)" type="danger" size="mini") Отменить
                  el-button.action__btn(v-if="scope.row.isCanSetCommission" @click="activateSetCommission(scope.row)" type="primary" size="mini") Комиссия
        .table-pagination__container
          el-pagination(
            style="margin-top: 20px"
            v-if='hasPagination',
            :total='orders.pagination.count',
            :page-size="per_page",
            :current-page='orders.pagination.page',
            background,
            layout='prev, pager, next',
            @current-change='loadOrders',
          )
    el-dialog(:visible.sync="isRequisiteAdding" :before-close="resetRequisiteAdd" title="Добавление реквизита" width="50%")
      .requisite-add__container
        .requisite-description(v-if="addingRequisiteOrder.description")
          .title Комментарии к заказу
          .messages__container
            .message(v-for="message in addingRequisiteOrder.messages") {{ message }}
        .requisite-add__input
          .title Номер карты
          el-input(v-model="addingRequisiteOrder.requisite" placeholder="Введите номер карты")
        .requisite-add__buttons
          el-button(type="success" @click="addRequisite()") Сохранить
          el-button(type="danger" @click="resetRequisiteAdd()") Отменить
    el-dialog(:visible.sync="isDealAdding" :before-close="resetDealAdd" title="Добавление сделки" width="80%")
      AdsList(
        v-if="isDealAdding"
        :id="addingDeal.id"
        :source="addingDeal.source"
        :fiat_amount="addingDeal.fiat_amount"
        :fiat_amount_active="addingDeal.fiat_amount_active"
        :fiat_amount_filled="addingDeal.fiat_amount_filled"
        @updateOrders="updateOrders()"
        )
    el-dialog(:visible.sync="isDealsShowing" :before-close="resetDealsShow" :title="dealsShowTitle" width="70%")
      DealsList(v-if="isDealsShowing" :order_id="showingDealsOrderId")
    el-dialog(:visible.sync="isOrderAdding" :before-close="resetOrderAdd" title="Создание заявки" width="70%")
      AddOrder(v-if="isOrderAdding" @resetOrderAdd="resetOrderAdd")
    el-dialog(:visible.sync="isCommissionSetting" :before-close="resetSetCommission" title="Установка комиссии" width="70%")
      .requisite-add__container
        .requisite-add__input {{ commissionOrder }}
          .title Сумма комиссии
          el-input(v-model="commissionOrder.commission" type="number" placeholder="Введите сумму комиссии")
        .requisite-add__buttons
          el-button(type="success" @click="startSetCommission()") Сохранить
          el-button(type="danger" @click="resetSetCommission()") Отменить
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import {
    CANCEL_ORDER,
    EDIT_ORDER,
    FINISH_ORDER,
    LOAD_ORDERS_LIST, LOAD_ORDERS_STATS,
    RENEW_ORDER,
    SET_COMMISSION, SWITCH_NOTE
  } from "../../store/const/orders";
  import AdsList from "./Ads";
  import DealsList from "./Deals";
  import AddOrder from "./Add";
  import instruments from '../../mixins/instruments';

  export default {
    name: 'OrdersList',
    components: {
      AdsList,
      DealsList,
      AddOrder,
    },
    mixins: [
        instruments,
    ],
    data() {
      return {
        page: 1,
        per_page: 50,
        statuses: {
          new: 'Новая',
          wait_confirm: 'Подтверждение продавцом',
          wait_card: 'Получение реквизита',
          confirm_check: 'Проверка подтверждения продавцом',
          confirmed: 'Ожидает подтверждения покупателя',
          canceled: 'Отменена',
          finished: 'Завершена',
          wait_finish: 'Завершение заявки',
          wait_deal_finish: 'Завершение сделок',
          wait_send: 'Открытие сделки',
          requisite_wait: 'Отправка реквизита в сделку',
          deal_error: 'Ошибка создания сделки',
          ad_unavailable: 'Ошибка создания сделки. Не найдено подходящее объявление',
          requisite_sent: 'Ожидание оплаты',
          finish_confirm: 'Подтверждение завершения заявки',
          finished_deal_canceled: 'Завершена, но сделка отменена',
          error: 'Ошибка',
          processing: 'В обработке',
          waiting: 'Ожидание обработки',
          manual_check: 'Ручная обработка',
          finishing: 'Завершение заявки',
        },
        isRequisiteAdding: false,
        addingRequisiteOrder: {
          id: null,
          source: '',
          description: null,
          messages: [],
          requisite: '',
        },
        isOrderAdding: false,
        activeOptions: [
          { key: 'all', locale: 'Все' },
          { key: 'active', locale: 'Активные' },
          { key: 'inactive', locale: 'Завершенные' },
        ],
        activeMode: 'all',
        searchValue: '',
        searchLoaded: false,
        searchMode: '',
        isDealAdding: false,
        addingDeal: {
          source: null,
          id: null,
          fiat_amount: null,
          fiat_amount_filled: null,
          fiat_amount_active: null,
        },
        isDealsShowing: false,
        showingDealsOrderId: null,
        finishAvailableStatuses: [
          'wait_card',
          'wait_send',
          'requisite_wait',
          'deal_error',
          'ad_unavailable',
          'requisite_sent',
        ],
        cancelAvailableStatuses: [
          'wait_send',
          'ad_unavailable',
        ],
        renewAvailableStatuses: [
          'deal_error',
        ],
        intervalHandle: null,
        statsIntervalHandle: null,
        isFirstLoading: true,
        commissionOrder: {
          id: null,
          source: '',
          commission: 0,
        },
        showStats: false,
        stats: {
          showTradersStats: false,
          amounts: [],
          traders: [],
          sums: [],
        },
        handlersPseodos: {
          finex: 'FX',
          saudi: 'SD',
        },
        handlersColors: {
          finex: '#ddf4ff',
          saudi: '#ddffee',
        }
      }
    },
    async mounted() {
      await this.loadOrders();
      await this.updateOrdersStats();
      this.isFirstLoading = false;
      this.startInterval();
      this.checkSearchMode();
    },
    beforeDestroy() {
      clearInterval(this.intervalHandle);
      clearInterval(this.statsIntervalHandle);
    },
    computed: {
      ...mapGetters([
        'loading',
        'error',
      ]),
      ...mapGetters('orders', [
        'orders',
        'ads'
      ]),
      ...mapGetters('settings', [
        'banks',
        'paymentSystems',
      ]),
      ...mapGetters('user', [
        'isAdmin',
        'isSuperAdmin',
        'isSupport',
        'isTrader',
        'showDealsInfo',
        'canSetCommission',
      ]),
      isLoading() {
        return this.isFirstLoading && this.loading(LOAD_ORDERS_LIST) === 'loading';
      },
      isError() {
        return this.loading(LOAD_ORDERS_LIST) === 'error';
      },
      errorMessage() {
        return this.error(LOAD_ORDERS_LIST);
      },
      hasPagination() {
        return this.orders.pagination.count > this.per_page;
      },
      dealsShowTitle() {
        return `Сделки по заявке №${this.showingDealsOrderId}`;
      },
      searchModes() {
        let modes = {
          requisites: { locale: 'По реквизитам', title: 'Реквизиты' },
          requisites_parted: { locale: 'По части реквизитов', title: 'Цифры' },
        };
        if (!this.isTrader) {
          modes = {
            id: { locale: 'По заявке', title: 'ID заявки' },
            source: { locale: 'По источнику', title: 'Имя пользователя' },
            ...modes,
          }
        }
        if (this.isAdmin || this.isSupport || this.showDealsInfo || this.isTrader) {
          modes.deal_id = { locale: 'По сделке', title: 'ID сделки' };
        }
        if (this.isAdmin) {
          modes.deal_offer = { locale: 'По трейдеру', title: 'Ник трейдера' };
        }
        return modes;
      },
      formattedOrders() {
        return this.orders.orders.map((order) => {
          const data = {
            ...order,
            isCanFinish: order.status !== 'finished' && (this.isSuperAdmin || (this.isAdmin && this.finishAvailableStatuses.includes(order.status))),
            isCanRenew: this.isAdmin && this.renewAvailableStatuses.includes(order.status),
            isCanCancel: order.status !== 'canceled' && (this.isSuperAdmin || this.cancelAvailableStatuses.includes(order.status)),
            isCanSetCommission: order.status === 'finished' && (this.isSuperAdmin || this.canSetCommission || this.isTrader) && !order.commission,
            isCanAddDeal: this.isAdmin && order.status === 'ad_unavailable',
          };
          if (order.bank) {
            const bankLocale = this.banks.find((current) => current.alias === order.bank);
            data.bank = bankLocale ? bankLocale.label : order.bank;
          }
          const paymentSystemLocale = this.paymentSystems.find((current) => current.alias === order.payment_system);
          data.payment_system = paymentSystemLocale ? paymentSystemLocale.label : order.payment_system;
          return data;
        })
      },
      isCommissionSetting() {
        return !!this.commissionOrder.id;
      },
      isCanOpenNewOrder() {
        return !this.isTrader;
      },
    },
    methods: {
      ...mapActions('orders', {
        loadOrdersList: LOAD_ORDERS_LIST,
        loadOrdersStats: LOAD_ORDERS_STATS,
        editOrder: EDIT_ORDER,
        finishOrder: FINISH_ORDER,
        cancelOrder: CANCEL_ORDER,
        renewOrder: RENEW_ORDER,
        setCommission: SET_COMMISSION,
        switchNote: SWITCH_NOTE,
      }),
      getDealRowStyle(deal) {
        if (deal.handler && this.handlersColors[deal.handler]) {
          return {
            background: this.handlersColors[deal.handler],
          };
        }
        return '';
      },
      tableRowClassName({row, rowIndex}) {
        const classes = [];
        if (row.notes) {
          if (row.notes.paid) {
            classes.push('row-success');
          } else if (row.notes.problem) {
            classes.push('row-danger');
          }
        }
        return classes;
      },
      startInterval(){
        this.intervalHandle = setInterval(() => this.loadOrders(this.page), 10000);
        this.statsIntervalHandle = setInterval(() => this.updateOrdersStats(), 60000);
      },
      async loadOrders(page = 1, id) {
        if (this.isLoading) {
          return;
        }
        const params = {
          page,
          per_page: this.per_page,
          active: this.activeMode,
        };
        if (this.searchValue) {
          params[this.searchMode] = this.searchValue;
        }
        await this.loadOrdersList({ params });
        this.page = page;
        this.searchLoaded = !!id;
      },
      async updateOrdersStats() {
        const request = await this.loadOrdersStats();
        if (request.success) {
          this.stats = request.data;
        }
      },
      openRequisiteAdd(order) {
        this.addingRequisiteOrder.source = order.source;
        this.addingRequisiteOrder.id = order.id;
        this.addingRequisiteOrder.description = order.description;
        this.addingRequisiteOrder.messages = order.description.split('|');
        this.isRequisiteAdding = true;
      },
      async addRequisite() {
        const requisites = this.addingRequisiteOrder.requisite.replace(/ /g, '').trim();
        if (requisites) {
          if (requisites.length > 13 && requisites.length < 19) {
            const request = await this.editOrder({
              source: this.addingRequisiteOrder.source,
              id: this.addingRequisiteOrder.id,
              data: { requisites, status: 'wait_send' }
            });
            if (request.success) {
              this.$message.success('Карта успешно добавлена');
              this.resetRequisiteAdd();
              await this.loadOrders(this.page);
            } else {
              this.$message.error(`Ошибка добавления карты: ${request.message || 'Неизвестная ошибка'}`);
            }
          } else {
            this.$message.error('Невалидная карта');
          }
        } else {
          this.$message.error('Введите карту');
        }
      },
      resetRequisiteAdd() {
        this.isRequisiteAdding = false;
        this.addingRequisiteOrder = {
          id: null,
          description: null,
          requisite: '',
        };
      },
      updateDefaultSearchMode() {
        localStorage.setItem('orders_searchMode', this.searchMode);
      },
      async searchOrder() {
        clearInterval(this.intervalHandle);
        this.searchValue = this.searchValue.trim();
        await this.loadOrders(1, this.searchValue);
      },
      resetSearch() {
        this.searchLoaded = false;
        this.searchValue = '';
        this.loadOrders();
        this.startInterval();
      },
      resetDealAdd() {
        this.isDealAdding = false;
      },
      openDealAdd(order) {
        this.addingDeal.fiat_amount = order.fiat_amount;
        this.addingDeal.fiat_amount_filled = order.fiat_amount_filled;
        this.addingDeal.fiat_amount_active = order.fiat_amount_active;
        this.addingDeal.id = order.id;
        this.addingDeal.source = order.source;
        this.isDealAdding = true;
      },
      updateOrders() {
        this.resetDealAdd();
        this.loadOrders(this.page);
      },
      resetDealsShow() {
        this.isDealsShowing = false;
        this.showingDealsOrderId = null;
      },
      activateOrderAdd() {
        this.isOrderAdding = true;
      },
      resetOrderAdd() {
        this.isOrderAdding = false;
      },
      resetSetCommission() {
        this.commissionOrder.id = null;
        this.commissionOrder.source = '';
        this.commissionOrder.commission = 0;
      },
      openDealsList(order_id) {
        if (this.isTrader) {
          return;
        }
        this.showingDealsOrderId = order_id;
        this.isDealsShowing = true;
      },
      async startFinishOrder(id, source) {
        this.$confirm(`Вы уверены, что хотите завершить заявку №${id}?`, 'Подтверждение').then(async () => {
          const request = await this.finishOrder({ id, source });
          if (request.success) {
            this.$message.success('Заявка успешно завершена');
          } else {
            this.$message.error(request.message || 'Неизвестная ошибка');
          }
        }).catch(() => {
          this.$message.info('Завершение отменено');
        });
      },
      async startCancelOrder(id, source) {
        this.$confirm(`Вы уверены, что хотите отменить заявку №${id}?`, 'Подтверждение').then(async () => {
          const request = await this.cancelOrder({ id, source });
          if (request.success) {
            this.$message.success('Заявка успешно отменена');
          } else {
            this.$message.error(request.message || 'Неизвестная ошибка');
          }
        }).catch(() => {
          this.$message.info('Действие отменено');
        });
      },
      async startRenewOrder(id, source) {
        this.$confirm(`Вы уверены, что хотите перезапустить заявку №${id}?`, 'Подтверждение').then(async () => {
          const request = await this.renewOrder({ id, source });
          if (request.success) {
            this.$message.success('Заявка успешно отменена');
          } else {
            this.$message.error(request.message || 'Неизвестная ошибка');
          }
        }).catch(() => {
          this.$message.info('Действие отменено');
        });
      },
      async activateSetCommission(order) {
        this.commissionOrder.source = order.source;
        this.commissionOrder.commission = 0;
        this.commissionOrder.id = this.isTrader ? order.deals[0].deal_id : order.id;
      },
      async startSetCommission() {
        this.$confirm(`Вы уверены, что хотите установить комиссию ${this.commissionOrder.commission} р. для заявки №${this.commissionOrder.id}?`, 'Подтверждение').then(async () => {
          const request = await this.setCommission(this.commissionOrder);
          if (request.success) {
            this.$message.success('Комиссия успешно установлена');
            this.resetSetCommission();
          } else {
            this.$message.error(request.message || 'Неизвестная ошибка');
          }
        }).catch((e) => {
          this.$message.info('Действие отменено');
        });
      },
      async copyOrderInfo(order) {
        let info = `${order.id}`;
        for (const deal of order.deals || []) {
          info += `\n\r№${deal.deal_id} (${deal.fiat_amount} р.)`
        }
        await this.copyTextToClipboard(info);
      },
      checkSearchMode() {
        const savedMode = localStorage.getItem('orders_searchMode');
        if (savedMode) {
          const searchModes = Object.keys(this.searchModes);
          if (searchModes.includes(savedMode)) {
            this.searchMode = savedMode;
          } else {
            this.searchMode = searchModes[0];
          }
        }
      },
      changeStatsShowStatus() {
        this.showStats = !this.showStats;
      },
      async handleOrderNote(order, type) {
        const request = await this.switchNote({ id: order.id, type });
        if (request.success) {
          order.notes[type] = !order.notes[type];
        } else {
          this.$message.error(request.message || 'Неизвестная ошибка');
        }
      },
    }
  };
</script>
<style scoped>
  .messages__container {
    border: 1px solid lightgrey;
    padding: 5px;
    margin: 5px auto;
  }

  .requisite-add__buttons {
    margin-top: 10px;
  }

  .filters__container {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  .search__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-reset__btn {
    padding: 11px;
    background-color: white;
    cursor: pointer;
  }

  .search-reset__btn:hover {
    /*padding: 10px;*/
    background-color: #f9f9f9;
    border: 1px solid #cccccc;
  }

  .fiat-amount__container {
    display: flex;
    align-items: center;
  }

  .fiat-add__btn {
    margin-left: 5px;
  }

  button.el-button--mini {
    padding: 5px 6px;
  }

  .deals__container {
    cursor: pointer;
  }

  .deals__container:hover {
    color: #161616;
  }

  .action-buttons__container {
    display: flex;
    flex-direction: column;
  }

  .action__btn {
    width: 100%;
    margin: 2px auto !important;
  }

  .note-icon {
      cursor: pointer;
      margin: 0 2px;
      font-size: 20px;
      font-weight: bold;
  }

  .copy__icon {
    margin-right: 5px;
    cursor: pointer;
  }

  .stats__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px;
      background-color: white;
      margin-bottom: 5px;
      font-size: 12px;
  }

  .stats__title {
      font-weight: bold;
  }

  .stats-handle__btn {
      width: 100%;
      text-align: center;
      cursor: pointer;
  }

  .stats-content__container {
      display: flex;
      width: 100%;
      margin: 2px auto;
      padding: 4px;
      border-top: 1px solid #ebeef5;
      border-bottom: 1px solid #ebeef5;
  }

  .stats-content__block {
      padding: 4px 8px;
      margin-right: 10px;
      background-color: #eaf3fb;
      border: 1px solid #d9e9f7;
      border-radius: 4px;
      font-family: monospace;
  }

  .stats-content__title {
      text-align: center;
      font-weight: bold;
      font-style: italic;
      margin-bottom: 5px;
  }

  .stats-content__val {
      display: flex;
      margin-bottom: 2px;
      justify-content: space-between;
  }

  .stats-content-val__title {
      text-decoration: underline;
      margin-right: 5px;
  }

  .deal__container {
      padding: 4px;
      margin-bottom: 4px;
  }

  .deal__container:last-child {
      margin-bottom: 0;
  }

  .deal-val {
      display: flex;
      justify-content: space-between;
  }
</style>
