const encryption = {
  methods: {
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";
      textArea.style.width = "0px";
      textArea.style.height = "0px";
      textArea.style.overflow = "hidden";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        document.body.removeChild(textArea);
        if (successful) {
          return true;
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        return this.fallbackCopyTextToClipboard(text);
      }
      navigator.clipboard.writeText(text).then(function () {
        return true;
      }, function (err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
  }
};

export default encryption
