<template lang="pug">
  .deals-list__container(v-loading="isLoading")
    el-table(
      v-loading="isLoading"
      :data="formattedDeals"
      style="width: 100%; font-size: 12px"
    )
      el-table-column(prop="deal_id" label="ID" width="100")
      el-table-column(prop="status" label="Статус" width="100")
      el-table-column(prop="fiat_amount" label="Сумма в рублях" width="120")
      el-table-column(prop="btc_amount" label="Сумма в BTC" width="100")
      el-table-column(prop="rate" label="Курс" width="100")
      el-table-column(prop="rate_percent" label="Процент курса" width="100")
      el-table-column(prop="open_by" label="Инициатор" width="100")
      el-table-column(prop="offer" label="Трейдер" width="100")
      el-table-column(prop="created" label="Создана" width="140")
      el-table-column(prop="updated" label="Обновлена" width="140")
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { LOAD_DEALS_LIST } from "../../store/const/orders";

  export default {
    name: 'DealsList',
    props: ['order_id'],
    data() {
      return {
        dealsStatuses: {
          requisite_wait: 'Отправка реквизита',
          requisite_sent: 'Ожидание оплаты',
          paid: 'Оплачена',
          canceled: 'Отменена',
          wait_deal_finish: 'Завершение',
          finished: 'Завершена',
        },
        deals: [],
      }
    },
    async mounted() {
      const dealsRequest = await this.loadDealsList({ order_id: this.order_id });
      if (dealsRequest.success) {
        this.deals = dealsRequest.data.deals;
      } else {
        this.$message.error(`Ошибка загрузки сделок: ${dealsRequest.message}`);
      }
    },
    computed: {
      ...mapGetters([
        'loading',
        'error',
      ]),
      isLoading() {
        return this.loading(LOAD_DEALS_LIST) === 'loading';
      },
      isError() {
        return this.loading(LOAD_DEALS_LIST) === 'error';
      },
      errorMessage() {
        return this.error(LOAD_DEALS_LIST);
      },
      formattedDeals() {
        return this.deals.map((deal) => {
          const created = new Date(deal.created);
          const updated = new Date(deal.updated);
          return {
            ...deal,
            status: this.dealsStatuses[deal.status] || deal.status,
            created: `${created.toLocaleDateString()} ${created.toLocaleTimeString()}`,
            updated: `${updated.toLocaleDateString()} ${updated.toLocaleTimeString()}`,
          }
        })
      }
    },
    methods: {
      ...mapActions('orders', {
        loadDealsList: LOAD_DEALS_LIST,
      }),
    }
  };
</script>
<style scoped>
</style>
