<template lang="pug">
  .add-order__container
    .add-order-input__block
      .add-order-input__title ID заявки
      el-input.add-order-input__value(v-model="addingOrder.id" placeholder="ID заявки")
    .add-order-input__block
      .add-order-input__title Платежная система
      el-select(v-model="addingOrder.payment_system")
        el-option(v-for="payment_system in paymentSystems" :label="payment_system.text" :value="payment_system.key" :key="`payment-system-${payment_system.key}`")
    .add-order-input__block(v-if="addingOrder.payment_system === 'card'")
      .add-order-input__title Тип карты
      el-select(v-model="addingOrder.card_type")
        el-option(v-for="card in cardTypes" :label="card.text" :value="card.key" :key="`card-type-${card.key}`")
    .add-order-input__block(v-if="addingOrder.payment_system === 'sbp'")
      .add-order-input__title Банк
      el-select(v-model="addingOrder.bank_id")
        el-option(v-for="bank in banks" :label="bank.label" :value="bank.id" :key="`bank-id-${bank.id}`")
    .add-order-input__block
      .add-order-input__title {{ addingOrder.payment_system === 'card' ? 'Номер карты' : 'Номер телефона' }}
      el-input.add-order-input__value(v-model="addingOrder.requisites" :placeholder="addingOrder.payment_system === 'card' ? 'Номер карты' : 'Номер телефона'")
    .add-order-input__block
      .add-order-input__title Сумма в рублях
      el-input.add-order-input__value(v-model="addingOrder.fiat_amount" placeholder="Сумма в рублях" type="number")
    .add-order__buttons
      el-button(type="success" size="mini" @click="startOrderAdding()") Добавить
      el-button(type="danger" size="mini" @click="resetOrderAdding()") Отменить
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ADD_ORDER } from "../../store/const/orders";

export default {
  name: 'AddOrder',
  data() {
    return {
      addingOrder: {
        id: '',
        requisites: '',
        payment_system: 'card',
        card_type: 'c2c',
        bank_id: undefined,
        fiat_amount: 0,
      },
      cardTypes: [
        { key: 'c2c', text: 'Card2Card' },
        { key: 'sber', text: 'Сбер' },
        { key: 'tinkoff', text: 'Тинькофф' },
      ],
      paymentSystems: [
        { key: 'card', text: 'Карты' },
        { key: 'sbp', text: 'СБП' },
      ]
    }
  },
  computed: {
    ...mapGetters('settings', [
      "banks",
    ]),
  },
  methods: {
    ...mapActions('orders', {
      addOrder: ADD_ORDER,
    }),
    resetOrderAdding() {
      this.$emit('resetOrderAdd');
    },
    async startOrderAdding() {
      let validateMethod;
      switch (this.addingOrder.payment_system) {
        case "card":
          validateMethod = this.validateAndCleanCreditCardNumber;
          break;
        case "sbp":
          validateMethod = this.validateAndCleanPhoneNumber;
          break;
        default:
          return this.$message.warning('Не найден метод валидации реквизита, обратитесь к администратору');
      }
      const validRequisites = validateMethod(this.addingOrder.requisites);
      if (validRequisites) {
        if (this.addingOrder.id) {
          if (this.addingOrder.fiat_amount > 0) {
            if (this.addingOrder.payment_system === 'sbp' && !this.addingOrder.bank_id) {
              return this.$message.error(`Необходимо указать банк получателя по СБП`);
            }
            const request = await this.addOrder(this.addingOrder);
            if (request.success) {
              this.$message.success('Заявка успешно добавлена');
              this.resetOrderAdding();
            } else {
              this.$message.error(`Ошибка добавления заявки: ${request.message || 'Неизвестная ошибка'}`);
            }
          } else {
            this.$message.error(`Неверная сумма заявки`);
          }
        } else {
          this.$message.error(`Не указан ID заявки`);
        }
      } else {
        this.$message.error(`Реквизиты невалидны: ${this.addingOrder.requisites}`);
      }
    },
    validateAndCleanCreditCardNumber(cardNumber) {
      const cleanedNumber = cardNumber.replace(/\D/g, '');
      if (parseInt(cleanedNumber) <= 0 || (!/\d{15,16}(~\W[a-zA-Z])*$/.test(cleanedNumber)) || cleanedNumber.length > 16) {
        return false;
      }
      const carray = [];
      for (let i = 0; i < cleanedNumber.length; i++) {
        carray[carray.length] = cleanedNumber.charCodeAt(i) - 48;
      }
      carray.reverse();
      let sum = 0;
      for (let i = 0; i < carray.length; i++) {
        let tmp = carray[i];
        if ((i % 2) !== 0) {
          tmp *= 2;
          if (tmp > 9) {
            tmp -= 9;
          }
        }
        sum += tmp;
      }
      if ((sum % 10) === 0) {
        return cleanedNumber;
      }
    },
    validateAndCleanPhoneNumber(phoneNumber) {
      // Убираем пробелы, дефисы, скобки и другие нецифровые символы
      const cleanedNumber = phoneNumber.replace(/\D/g, '');

      // Проверяем, что номер начинается с 7 или 8 и содержит 11 цифр
      const isValid = /^7\d{10}$/.test(cleanedNumber) || /^8\d{10}$/.test(cleanedNumber);

      if (isValid) {
        return cleanedNumber;
      }
    }
  }
};
</script>
<style scoped>
.add-order-input__block {
    margin: 10px 0;
}
</style>
