import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import {isActiveToken} from '../mixins/auth';
import Login from "../views/Login/Login";
import Home from "../views/Home/Index";
import OrdersList from "../views/Orders/List";
import BinsList from "../views/Bin/List";
import Stats from "../views/Stats/Stats";
import Settings from "../views/Settings/Settings";
import UsersList from "../views/Users/List";
import Balances from "../views/Stats/Balances";
import Groups from "../views/Groups/List";
import TradersList from "../views/Traders/List";
import cfg from '../store/config';


Vue.use(Router);

const ifAuthenticated = (to, from, next) => {
  const role = store.getters['user/getRole'];
  if (to.meta && to.meta.access) {
    const defaultRoute = '/orders';
    if (!to.meta.access.includes(role)) {
      next(defaultRoute);
      return;
    }
  }
  if (isActiveToken(store.getters['user/getToken'])) {
    next();
    return;
  }
  next('/login');
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      beforeEnter: ifAuthenticated,
      component: Home,
      children: [
        {
          path: '',
          beforeEnter: ifAuthenticated,
          component: OrdersList,
          meta: {
            access: ['client', 'support', 'admin', 'superadmin', 'trader'],
            title: 'Заявки',
          }
        },
        {
          path: 'orders',
          name: 'OrdersList',
          beforeEnter: ifAuthenticated,
          component: OrdersList,
          meta: {
            access: ['client', 'support', 'admin', 'superadmin', 'trader'],
            title: 'Заявки',
          }
        },
        {
          path: 'bins',
          name: 'BinsList',
          beforeEnter: ifAuthenticated,
          component: BinsList,
          meta: {
            access: ['admin', 'superadmin'],
            title: 'Бины',
          }
        },
        {
          path: 'stats',
          name: 'Stats',
          beforeEnter: ifAuthenticated,
          component: Stats,
          meta: {
            access: ['admin', 'superadmin', 'client'],
            title: 'Статистика',
          }
        },
        {
          path: 'balances',
          name: 'Balances',
          beforeEnter: ifAuthenticated,
          component: Balances,
          meta: {
            access: ['superadmin', 'client', 'partner'],
            title: 'История баланса',
          }
        },
        {
          path: 'users',
          name: 'UsersList',
          beforeEnter: ifAuthenticated,
          component: UsersList,
          meta: {
            access: ['superadmin'],
            title: 'Пользователи',
          }
        },
        {
          path: 'groups',
          name: 'Groups',
          beforeEnter: ifAuthenticated,
          component: Groups,
          meta: {
            access: ['superadmin'],
            title: 'Группы',
          }
        },
        {
          path: 'traders',
          name: 'TradersList',
          beforeEnter: ifAuthenticated,
          component: TradersList,
          meta: {
            access: ['superadmin'],
            title: 'Трейдеры',
          }
        },
        {
          path: 'settings',
          name: 'Settings',
          beforeEnter: ifAuthenticated,
          component: Settings,
          meta: {
            access: ['superadmin'],
            title: 'Настройки',
          }
        },
      ],
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Авторизация',
      }
    },
  ],
});


let DEFAULT_TITLE = cfg.sitePrefix || '';
if (DEFAULT_TITLE) {
  DEFAULT_TITLE += ' - ';
}
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    if (to.meta.title) {
      document.title = `${DEFAULT_TITLE}${to.meta.title}`;
    } else {
      if (DEFAULT_TITLE) {
        document.title = DEFAULT_TITLE;
      } else {
        document.title = 'Frontend';
      }
    }
  });
});
export default router;
