export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const EDIT_USER = 'EDIT_USER';
export const CREATE_USER = 'CREATE_USER';
export const LOAD_USERS_LIST = 'LOAD_USERS_LIST';
export const EDIT_BALANCE = 'EDIT_BALANCE';
export const LOAD_STATS = 'LOAD_STATS';
export const LOAD_CSV_STATS = 'LOAD_CSV_STATS';
export const LOAD_BALANCE_CHANGES = 'LOAD_BALANCE_CHANGES';
export const LOAD_BALANCE_USERS = 'LOAD_BALANCE_USERS';
export const CHANGE_SESSION = 'CHANGE_SESSION';
export const DELETE_SESSION = 'DELETE_SESSION';
export const LOAD_GROUPS = 'LOAD_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const ADD_MEMBER_TO_GROUP = 'ADD_MEMBER_TO_GROUP';
export const REMOVE_MEMBER_FROM_GROUP = 'REMOVE_MEMBER_FROM_GROUP';
