<template lang="pug">
  #app(style="height: 100vh")
    router-view
</template>

<script>

  export default {
    name: 'app',
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100vh;
  }

  #main {
    padding: 10px;
  }

  .title {
    font-weight: bold;
  }
</style>
