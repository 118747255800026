import Vue from 'vue'
import router from '../../router/';
import {
  ADD_GROUP, ADD_MEMBER_TO_GROUP,
  AUTH_LOGIN,
  AUTH_LOGOUT, CHANGE_SESSION,
  CREATE_USER, DELETE_SESSION,
  EDIT_BALANCE,
  EDIT_USER,
  LOAD_BALANCE_CHANGES,
  LOAD_BALANCE_USERS,
  LOAD_CSV_STATS, LOAD_GROUPS,
  LOAD_STATS,
  LOAD_USERS_LIST, REMOVE_GROUP, REMOVE_MEMBER_FROM_GROUP,
} from '../const/user';
import { HTTP_DELETE, HTTP_GET, HTTP_POST, HTTP_PUT } from '../const';
import { RESET_ORDERS } from "../const/orders";
import { RESET_SETTINGS } from "../const/settings";

export default {
  namespaced: true,
  state: {
    id: localStorage.getItem('id') || '',
    token: localStorage.getItem('token') || '',
    username: localStorage.getItem('username') || '',
    role: localStorage.getItem('role') || 'client',
    sessions: localStorage.getItem('sessions') ? JSON.parse(localStorage.getItem('sessions')) : [],
    showDealsInfo: localStorage.getItem('showDealsInfo') === 'true' || false,
    canSetCommission: localStorage.getItem('canSetCommission') === 'true' || false,
    users: [],
    stats: {},
    balanceHistory: {
      user: '',
      balances: [],
      pagination: {},
    },
    balanceUsers: [],
    groups: [],
  },
  getters: {
    sessions: state => state.sessions,
    getRole: state => state.role,
    getToken: state => state.token,
    isAdmin: state => state.role === 'admin' || state.role === 'superadmin',
    isSuperAdmin: state => state.role === 'superadmin',
    isSupport: state => state.role === 'support',
    isTrader: state => state.role === 'trader',
    users: state => state.users,
    groups: state => state.groups,
    stats: state => state.stats,
    username: state => state.username,
    balanceHistory: state => state.balanceHistory,
    balanceUsers: state => state.balanceUsers,
    showDealsInfo: state => state.showDealsInfo,
    canSetCommission: state => state.canSetCommission,
  },
  actions: {
    [AUTH_LOGIN]: async ({ dispatch, commit, state }, { name, password, data }) => {
      const existSession = state.sessions.find((session) => session.username === name);
      if (existSession) {
        return {
          success: false,
          message: `Сессия с пользователем ${name} уже открыта на этом клиенте, воспользуйтесь выбором сессии`
        }
      }

      const request = await dispatch(HTTP_POST, {
        method: AUTH_LOGIN,
        body: { name, password, data },
        with_token: false,
        no_commit: true,
        namespace: 'user',
      }, { root: true });

      if (request && request.data) {
        if (request.data.success) {
          const data = request.data.data;
          const token = data.token;
          const user = data.user;
          commit(AUTH_LOGIN, {
            token,
            id: user.id,
            username: user.name,
            role: user.role,
            showDealsInfo: user.show_deals_info,
            canSetCommission: user.can_set_commission,
            isNewSession: true,
          });
          await router.push({ name: 'OrdersList' });
          return { success: true };
        } else {
          return { success: false, message: request.data.message }
        }
      } else {
        return { success: false, message: 'Bad request' }
      }
    },
    [AUTH_LOGOUT]: async ({ commit }) => {
      commit(`orders/${RESET_ORDERS}`, null, { root: true });
      commit(`settings/${RESET_SETTINGS}`, null, { root: true });
      commit(AUTH_LOGOUT);
      await router.push({ name: 'Login' });
    },
    [CHANGE_SESSION]: async ({ commit }, session) => {
      commit(`orders/${RESET_ORDERS}`, null, { root: true });
      commit(`settings/${RESET_SETTINGS}`, null, { root: true });
      commit(AUTH_LOGIN, { ...session, isNewSession: false });
      await router.push({ name: 'OrdersList' });
    },
    [DELETE_SESSION]: async ({ commit }, session) => {
      commit(`orders/${RESET_ORDERS}`, null, { root: true });
      commit(`settings/${RESET_SETTINGS}`, null, { root: true });
      commit(DELETE_SESSION, session.username);
    },
    [LOAD_USERS_LIST]: async ({ dispatch }) => {
      await dispatch(HTTP_GET, {
        method: LOAD_USERS_LIST,
        namespace: 'user',
      }, { root: true });
    },
    [CREATE_USER]: async ({ dispatch }, user) => {
      const request = await dispatch(HTTP_POST, {
        method: CREATE_USER,
        namespace: 'user',
        body: user,
      }, { root: true });
      if (request && request.data) {
        return request.data;
      } else {
        return { success: false, message: 'Bad reqest' };
      }
    },
    [EDIT_USER]: async ({ dispatch }, { id, data }) => {
      const request = await dispatch(HTTP_PUT, {
        method: EDIT_USER,
        namespace: 'user',
        body: data,
        replace: { id },
      }, { root: true });
      if (request && request.data) {
        return request.data;
      } else {
        return { success: false, message: 'Bad reqest' };
      }
    },
    [EDIT_BALANCE]: async ({ dispatch }, { id, data }) => {
      const request = await dispatch(HTTP_PUT, {
        method: EDIT_BALANCE,
        namespace: 'user',
        body: data,
        replace: { id },
      }, { root: true });
      if (request && request.data) {
        return request.data;
      } else {
        return { success: false, message: 'Bad reqest' };
      }
    },
    [LOAD_STATS]: async ({ dispatch }, { from, to, stats_by }) => {
      await dispatch(HTTP_GET, {
        method: LOAD_STATS,
        namespace: 'user',
        params: { from, to, stats_by },
      }, { root: true });
    },
    [LOAD_CSV_STATS]: async ({ dispatch }, { from, to, user, stats_by }) => {
      const request = await dispatch(HTTP_GET, {
        method: LOAD_CSV_STATS,
        namespace: 'user',
        isFile: true,
        no_commit: true,
        params: { from, to, user, stats_by },
      }, { root: true });
      const blob = new Blob([request.data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `report-${user}.csv`;
      link.click()
      URL.revokeObjectURL(link.href)
    },
    [LOAD_BALANCE_USERS]: async ({ dispatch }) => {
      await dispatch(HTTP_GET, {
        method: LOAD_BALANCE_USERS,
        namespace: 'user',
      }, { root: true });
    },
    [LOAD_BALANCE_CHANGES]: async ({ dispatch, commit }, params) => {
      const request = await dispatch(HTTP_GET, {
        method: LOAD_BALANCE_CHANGES,
        namespace: 'user',
        no_commit: true,
        params,
      }, { root: true });
      if (request && request.data && request.data.success) {
        commit(LOAD_BALANCE_CHANGES, { data: request.data.data, user: params.user });
      }
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
    [LOAD_GROUPS]: async ({ dispatch }) => {
      await dispatch(HTTP_GET, {
        method: LOAD_GROUPS,
        namespace: 'user',
      }, { root: true });
    },
    [ADD_GROUP]: async ({ dispatch }, { group, users }) => {
      const request = await dispatch(HTTP_POST, {
        method: ADD_GROUP,
        body: { group, users },
        no_commit: true,
        namespace: 'user',
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
    [REMOVE_GROUP]: async ({ dispatch }, { group }) => {
      const request = await dispatch(HTTP_DELETE, {
        method: REMOVE_GROUP,
        body: { group },
        no_commit: true,
        namespace: 'user',
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
    [ADD_MEMBER_TO_GROUP]: async ({ dispatch }, { group, user }) => {
      const request = await dispatch(HTTP_POST, {
        method: ADD_MEMBER_TO_GROUP,
        replace: { group },
        body: { user },
        no_commit: true,
        namespace: 'user',
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
    [REMOVE_MEMBER_FROM_GROUP]: async ({ dispatch }, { group, user }) => {
      const request = await dispatch(HTTP_DELETE, {
        method: REMOVE_MEMBER_FROM_GROUP,
        replace: { group },
        body: { user },
        no_commit: true,
        namespace: 'user',
      }, { root: true });
      return request ? request.data : { success: false, message: 'Неудачный запрос' };
    },
  },
  mutations: {
    [AUTH_LOGIN]: (state, { token, id, username, role, showDealsInfo, isNewSession, canSetCommission }) => {
      localStorage.setItem('token', token);
      localStorage.setItem('id', id);
      localStorage.setItem('username', username);
      localStorage.setItem('role', role);
      localStorage.setItem('showDealsInfo', showDealsInfo);
      localStorage.setItem('canSetCommission', canSetCommission);
      if (isNewSession) {
        const sessions = [...state.sessions, { token, id, username, role, showDealsInfo, canSetCommission }];
        localStorage.setItem('sessions', JSON.stringify(sessions));
        Vue.set(state, 'sessions', sessions);
      }
      Vue.set(state, 'token', token);
      Vue.set(state, 'id', id);
      Vue.set(state, 'username', username);
      Vue.set(state, 'role', role);
      Vue.set(state, 'showDealsInfo', showDealsInfo);
      Vue.set(state, 'canSetCommission', canSetCommission);
    },
    [AUTH_LOGOUT]: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      localStorage.removeItem('showDealsInfo');
      localStorage.removeItem('canSetCommission');
      const sessions = state.sessions.filter((session) => session.username !== state.username);
      localStorage.setItem('sessions', JSON.stringify(sessions));
      Vue.set(state, 'token', '');
      Vue.set(state, 'id', '');
      Vue.set(state, 'username', '');
      Vue.set(state, 'role', '');
      Vue.set(state, 'showDealsInfo', false);
      Vue.set(state, 'canSetCommission', false);
      Vue.set(state, 'sessions', sessions);
    },
    [DELETE_SESSION]: (state, username) => {
      const sessions = state.sessions.filter((session) => session.username !== username);
      localStorage.setItem('sessions', JSON.stringify(sessions));
      Vue.set(state, 'sessions', sessions);
    },
    [LOAD_USERS_LIST]: (state, { data = {} }) => {
      if (data.users) {
        Vue.set(state, 'users', data.users);
      }
    },
    [LOAD_STATS]: (state, { data = {} }) => {
      Vue.set(state, 'stats', data);
    },
    [LOAD_BALANCE_USERS]: (state, { data = {} }) => {
      Vue.set(state, 'balanceUsers', data);
    },
    [LOAD_BALANCE_CHANGES]: (state, { data, user }) => {
      Vue.set(state.balanceHistory, 'user', user);
      Vue.set(state.balanceHistory, 'balances', data.balances);
      Vue.set(state.balanceHistory, 'pagination', data.pagination);
    },
    [LOAD_GROUPS]: (state, { data = [] }) => {
      Vue.set(state, 'groups', data);
    },
  },
};
