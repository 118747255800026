import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import router from "../router/";

import user from './modules/user';
import settings from './modules/settings';
import orders from './modules/orders';
import bins from './modules/bins';

import encryption from '../mixins/encryption';

import cfg from './config';
import {
  ERROR,
  HEALTH_CHECK,
  HTTP_DELETE,
  HTTP_GET,
  HTTP_POST,
  HTTP_PUT,
  LOADING,
  PAGINATION,
  REMOVE_ERROR,
  CHANGE_RELOAD_STATUS,
} from './const';
import path from './const/path';
import { AUTH_LOGIN, AUTH_LOGOUT } from './const/user';

Vue.use(Vuex);

/**
 * Функция формирования url
 * Заменяет данные, например из /user_info/:id => /user_info/1
 * @param url
 * @param params
 * @returns {string}
 */
const getUrl = (url, params = {}) => {
  const currentApiUrl = cfg.testMode ? cfg.baseURLApi : '';
  if (!url) {
    console.error('ERROR URL', url, params);
    return currentApiUrl;
  }
  let path = url;
  if (Object.keys(params).length > 0) {
    Object.keys(params).forEach(function (key) {
      path = path.replace(`:${key}:`, params[key]);
    });
  }
  return currentApiUrl + path;
};

/**
 * Универсальный метод обработки ошибкок при запросах
 * @param e
 * @param commit
 * @param method
 * @param error
 * @returns {Promise<{response}|*>}
 */
const handleError = async (e, commit, method, error) => {
  commit(LOADING, {
    name: method,
    value: 'error',
  });
  if (e.response) {
    if (e.response.status === 401 && method !== AUTH_LOGIN) {
      commit(`user/${AUTH_LOGOUT}`);
      await router.push({ name: 'Login' });
    }
  }

  let error_message = 'Что-то пошло не так, обратитесь в тех. поддержку';
  if (error) {
    error_message = error;
  } else if (e.response && (e.response.data.error || e.response.data.title || e.response.data.message)) {
    error_message = e.response.data.error || e.response.data.title || e.response.data.message;
  }
  commit(ERROR, {
    name: method,
    value: error_message,
  });
};

export default new Vuex.Store({
  modules: {
    user,
    settings,
    orders,
    bins,
  },
  state: {
    loading: {},
    error: {},
    pagination: {},
    mode: '',
    reload: false,
  },
  getters: {
    loading: (state) => name => name ? state.loading[name] : state.loading,
    error: (state) => name => name ? state.error[name] : state.error,
    pagination: (state) => name => name ? state.pagination[name] : state.pagination,
    baseUrl: () => cfg.baseURLApi,
    mode: (state) => state.mode,
    btc_address: (state) => state.btc_address,
    name: (state) => state.name,
    reload: (state) => state.reload,
  },
  actions: {
    /**
     * Запрос GET
     * @param state
     * @param commit
     * @param method - константа на который будет идти запрос
     * @param params - объект с параметрами, ключ -> значение, будет добавлен ?key=value
     * @param with_token - отправлять токен?
     * @param no_commit - не делать мутацию после запроса? По умолчанию мутация выполняется
     * @param args - дополнительные аргументы
     * @returns {Promise<AxiosResponse<T>>}
     */
    [HTTP_GET]: async (
      { state, commit },
      { method, params, with_token = true, no_commit = false, ...args },
    ) => {
      console.info({ log: 'HTTP_GET' }, method, params, with_token, no_commit, args);
      const config = {};
      if (with_token) {
        config.headers = { Authorization: 'Bearer ' + state.user.token };
      }
      if (params) {
        config.params = params;
      }
      let replace = {};
      if (args.replace) {
        replace = args.replace
      }
      if (args.isFile) {
        config.responseType = 'blob';
      }
      commit(LOADING, {
        name: method,
        value: 'loading',
      });
      commit(REMOVE_ERROR, method);
      try {
        const url = getUrl(path[method], replace);
        let request = await axios.get(url, config);
        commit(LOADING, {
          name: method,
          value: 'loaded',
        });
        if (no_commit === false) {
          if (args.namespace) {
            method = args.namespace + '/' + method;
          }
          if (request.data && request.data.success) {
            commit(method, { data: request.data.data, params });
          } else {
            commit(method, { data: request.data, params });
          }
        }
        if (request.data.pagination) {
          commit(PAGINATION, { name: method, data: request.data.pagination });
        }
        return request;
      } catch (e) {
        console.error('ERROR_HTTP_GET', e);
        await handleError(e, commit, method);
      }
    },

    /**
     * Запрос POST
     * @param state
     * @param commit
     * @param method - константа на который будет идти запрос
     * @param params - параметры для замены в url
     * @param body - тело/данные запроса
     * @param with_token - отправлять токен?
     * @param no_commit - не делать мутацию после запроса? По умолчанию мутация выполняется
     * @param args - дополнительные аргументы
     * @returns {Promise<AxiosResponse<T>|*>}
     */
    [HTTP_POST]: async (
      { state, commit },
      { method, params, body = {}, with_token = true, no_commit = false, encrypt_body = true, ...args },
    ) => {
      console.info({ log: 'HTTP_POST' }, method, params, body, with_token, no_commit, encrypt_body, args);
      const config = {};
      if (with_token) {
        config.headers = {};
        if (with_token) {
          config.headers.Authorization = 'Bearer ' + state.user.token;
        }
      }
      commit(LOADING, {
        name: method,
        value: 'loading',
      });
      commit(REMOVE_ERROR, method);

      try {
        if (args.upload) {
          config.headers['Content-Type'] = 'multipart/form-data';
          body = new FormData();
          if (args.phrases) {
            body.append('phrases', args.phrases);
          } else {
            body.append('file', args.file);
          }
          if (args.data) {
            body.append('data', JSON.stringify(args.data));
          }
        }

        let replace = {};
        if (args.replace) {
          replace = args.replace
        }
        const url = getUrl(path[method], { ...params, ...replace });

        if (Object.keys(body).length > 0 && encrypt_body) {
          body = { data: encryption.methods.encrypt(JSON.stringify(body)), encrypted: true };
        }

        let request = await axios.post(url, body, config);
        commit(LOADING, {
          name: method,
          value: 'loaded',
        });
        if (no_commit === false) {
          if (args.namespace) {
            method = args.namespace + '/' + method;
          }
          commit(method, { data: request.data, params });
        }
        if (request.data.pagination) {
          commit(PAGINATION, { name: method, data: request.data.pagination });
        }
        return request;
      } catch (e) {
        console.error('ERROR_HTTP_POST', e);
        await handleError(e, commit, method);
      }
    },

    /**
     * Запрос PUT
     * @param state
     * @param commit
     * @param method - константа на который будет идти запрос
     * @param params - параметры для замены в url
     * @param body - тело/данные запроса
     * @param with_token - отправлять токен?
     * @param no_commit - не делать мутацию после запроса? По умолчанию мутация выполняется
     * @param args - дополнительные аргументы
     * @returns {Promise<AxiosResponse<T>|*>}
     */
    [HTTP_PUT]: async (
      { state, commit },
      { method, params, body = {}, with_token = true, no_commit = false, encrypt_body = true, ...args },
    ) => {
      console.info({ log: 'HTTP_PUT' }, method, params, body, with_token, no_commit, encrypt_body, args);
      const config = {};
      if (with_token) {
        config.headers = { Authorization: 'Bearer ' + state.user.token };
      }
      commit(LOADING, {
        name: method,
        value: 'loading',
      });
      commit(REMOVE_ERROR, method);
      try {
        let replace = {};
        if (args.replace) {
          replace = args.replace
        }
        if (Object.keys(body).length > 0 && encrypt_body) {
          body = { data: encryption.methods.encrypt(JSON.stringify(body)), encrypted: true };
        }
        let request = await axios.put(getUrl(path[method], { ...params, ...replace }), body, config);
        commit(LOADING, {
          name: method,
          value: 'loaded',
        });
        if (no_commit === false) {
          if (args.namespace) {
            method = args.namespace + '/' + method;
          }
          commit(method, { data: request.data, params });
        }
        if (request.data.pagination) {
          commit(PAGINATION, { name: method, data: request.data.pagination });
        }
        return request;
      } catch (e) {
        console.error('ERROR_HTTP_PUT', e);
        await handleError(e, commit, method);
      }
    },

    /**
     * Запрос DELETE
     * @param state
     * @param commit
     * @param method - константа на который будет идти запрос
     * @param params - параметры для замены в url
     * @param body - тело/данные запроса
     * @param with_token - отправлять токен?
     * @param no_commit - не делать мутацию после запроса? По умолчанию мутация выполняется
     * @param args - дополнительные аргументы
     * @returns {Promise<AxiosResponse<T>|*>}
     */
    [HTTP_DELETE]: async (
      { state, commit },
      { method, params, body = {}, with_token = true, no_commit = false, encrypt_body = true, ...args },
    ) => {
      console.info({ log: 'HTTP_DELETE' }, method, params, body, with_token, no_commit, encrypt_body, args);
      const config = {};
      if (with_token) {
        config.headers = { Authorization: 'Bearer ' + state.user.token };
      }
      if (Object.keys(body).length > 0 && encrypt_body) {
        body = { data: encryption.methods.encrypt(JSON.stringify(body)), encrypted: true };
      }
      if (Object.keys(body).length > 0) {
        config.data = body;
      }
      commit(LOADING, {
        name: method,
        value: 'loading',
      });
      commit(REMOVE_ERROR, method);
      try {
        let replace = {};
        if (args.replace) {
          replace = args.replace
        }
        const url = getUrl(path[method], { ...params, ...replace });
        let request = await axios.delete(url, config);
        commit(LOADING, {
          name: method,
          value: 'loaded',
        });
        if (no_commit === false) {
          if (args.namespace) {
            method = args.namespace + '/' + method;
          }
          commit(method, { data: request.data, params });
        }
        if (request.data.pagination) {
          commit(PAGINATION, { name: method, data: request.data.pagination });
        }
        return request;
      } catch (e) {
        console.error('ERROR_HTTP_DELETE', e);
        await handleError(e, commit, method);
      }
    },
    /**
     * Запрос проверки работоспособности сервера
     * @param state
     * @param commit
     * @param dispatch
     * @returns {Promise<void>}
     */
    [HEALTH_CHECK]: async ({ dispatch }) => {
      let request = await dispatch(HTTP_GET, {
        method: HEALTH_CHECK,
        with_token: false,
        no_commit: true,
      });
      if (!request) {
        await router.push({ name: 'ServerError' });
      }
    },
    [CHANGE_RELOAD_STATUS]: async ({ commit }, { status }) => {
      commit(CHANGE_RELOAD_STATUS, { status });
    },
  },
  mutations: {
    [LOADING]: (state, { name, value }) => {
      Vue.set(state.loading, name, value);
    },
    [ERROR]: (state, { name, value }) => {
      Vue.set(state.error, name, value);
    },
    [REMOVE_ERROR]: (state, name) => {
      Vue.set(state.error, name, null);
    },
    [PAGINATION]: (state, { name, data }) => {
      Vue.set(state.pagination, name, data);
    },
    [CHANGE_RELOAD_STATUS]: (state, { status }) => {
      Vue.set(state, 'reload', status);
    },
  },
});
