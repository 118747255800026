<template lang="pug">
  .login
    el-card.login-form(style="position: relative; top: 60px;")
      h2 Авторизация
      el-alert(
        v-if="isError"
        type="error"
        style="margin-bottom: 20px"
      ) {{ errorMessage }}
      .login__container
        .type-select__container
          el-radio-group(v-model="loginType")
            el-radio-button(label="session_select" :disabled="!sessionLoginAvailable") Выбор сессии
            el-radio-button(label="session_new") Новая сессия
        SessionPick(v-if="loginType === 'session_select'")
        el-form.login-form(v-else :model="data" :rules="rules" ref="authForm" @submit.native.prevent="login")
          el-form-item(prop="name")
            el-input(v-model="data.name" placeholder="Email" prefix-icon="fas fa-user")
          el-form-item(prop="password")
            el-input(v-model="data.password" placeholder="Пароль" type="password" prefix-icon="fas fa-lock" show-password)
          el-form-item
            el-button.login-button(:loading="isLoading" :disabled="isLoading" type="primary" native-type="submit" block="") Войти
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { AUTH_LOGIN } from '../../store/const/user';
import SessionPick from '../Login/SessionPick';

export default {
  name: 'LoginPage',
  components: {
    SessionPick,
  },
  data() {
    return {
      loginType: 'session_select',
      data: {
        name: '',
        password: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: "Имя пользователя обязательно",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Вы не ввели пароль",
            trigger: "blur",
          },
        ],
      },
      browserInfo: {
        width: window.innerWidth,
        height: window.innerHeight,
        screen: window.screen,
      },
    }
  },
  mounted() {
    this.loginType = this.sessionLoginAvailable ? 'session_select' : 'session_new';
  },
  computed: {
    ...mapGetters([
      'loading',
      'error',
    ]),
    ...mapGetters('user', [
      'sessions',
    ]),
    isLoading() {
      return this.loading(AUTH_LOGIN) === 'loading';
    },
    isError() {
      return this.loading(AUTH_LOGIN) === 'error';
    },
    errorMessage() {
      return this.error(AUTH_LOGIN);
    },
    sessionLoginAvailable() {
      return this.sessions.length > 0;
    }
  },
  methods: {
    ...mapActions('user', {
      loginRequest: AUTH_LOGIN,
    }),
    getBrowserInfo() {
      var ua = navigator.userAgent, tem,
          M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(' ');
    },
    getOsInfo() {
      var OSName = "Unknown OS";
      if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
      if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
      if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
      if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
      return OSName;
    },
    async login() {
      try {
        const data = {
          browser: {
            main: this.browserInfo,
            advanced: this.getBrowserInfo(),
          },
          os: this.getOsInfo(),
        };
        await this.$refs.authForm.validate();
        const request = await this.loginRequest({
          name: this.data.name,
          password: this.data.password,
          data,
        });
        if (!request.success) {
          this.$message.error(`Ошибка авторизации: ${request.message || 'Неизвестная ошибка'}`)
        }
      } catch (e) {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.type-select__container {
  margin-bottom: 10px;
}
.login-form {
  margin: 0 auto;
  max-width: 500px;
}
</style>
